import { AIRCRAFT_TYPES_DATA, COUNTRIES } from '@/Utils/constants';

export const debounce = (func, timeout = 300) => {
  let timer: NodeJS.Timeout;
  return (...args: Parameters<typeof func>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export const addDays = (days: number, date = new Date()) => {
  const newDate = new Date();
  newDate.setDate(date.getDate() + days);
  return newDate;
};

/**
 * will format date to return mm/dd/yyyy
 */

export const formatDate = (
  date?: Date,
  format: 'yyyy-mm-dd' | 'dd-mm-yyyy' | 'mm/dd/yyyy' = 'mm/dd/yyyy',
) => {
  if (!date || isNaN(date.getTime())) {
    return '';
  }

  try {
    let year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(
      date,
    );
    let month = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(
      date,
    );
    let day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);

    return format
      ?.replace('yyyy', year)
      .replace('mm', month)
      .replace('dd', day);
    // return `${year}-${month}-${day}`;
  } catch (error) {
    return '';
  }
};
export const formatTime = (date?: Date) => {
  if (!date || isNaN(date.getTime())) {
    return '';
  }

  try {
    let time = new Intl.DateTimeFormat('en-US', {
      timeStyle: 'short',
    }).format(date);
    return time;
  } catch (error) {
    return '';
  }
};
// the reverse of @formatDate mm/dd/yyyy
export const strDate = (str = '') => {
  if (str === '') return undefined;
  const [month, day, year] = str.split('/').map(str => parseInt(str));
  return new Date(year, month - 1, day);
};

/**
 * @returns {date : mm/dd/yyyy , time : hh:mm AM/PM}
 */
export const createDateObj = (str: string) => {
  const date = new Date(str);
  return {
    date: formatDate(date),
    time: formatTime(date),
  };
};
// make a date object out of a string like "12/13/202112:00:00-05:00"
// need to add a space after the year
export const cleanDate = (d: string) => {
  let temp = d.split('');
  temp.splice(10, 0, ' ');
  return new Date(temp.join(''));
};
// Create our number formatter.
export const formatMoney = (amount: number, currency?: string) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ?? 'USD',
  });

  return formatter.format(amount);
};
/**
 * format and calculate time
 * @param hours number of hours ex: 1.5 => 1:30
 */
export const calculateTime = (hours: number) => {};

export const objToParams = (obj: object) => {
  var params = '';
  for (var key in obj) {
    if (params != '') {
      params += '&';
    }
    params += key + '=' + encodeURIComponent(obj[key]);
  }
  return params;
};

export const sleep = (ms = 1000) => {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
};
// DON'T REMOVE: used to exclude these classes from css purge cuz they are formed dynamically
// 'bg-gray-500',
// 'bg-purple-500',
// 'bg-green-700',
// 'bg-green-300',
// 'bg-blue-500',
// 'bg-indigo-300',
// 'bg-indigo-400',
// 'bg-indigo-600',
// 'bg-red-300',
// 'bg-red-500',
// 'bg-yellow-600',
// 'bg-yellow-700',
const colors = [
  'gray-500',
  'purple-500',
  'green-700',
  'green-300',
  'blue-500',
  'indigo-300',
  'indigo-400',
  'indigo-600',
  'red-300',
  'red-500',
  'yellow-600',
  'yellow-700',
];
const hexColors = [
  '#6B7280',
  '#8B5CF6',
  '#047857',
  '#6EE7B7',
  '#3B82F6',
  '#A5B4FC',
  '#818CF8',
  '#4F46E5',
  '#FCA5A5',
  '#EF4444',
  '#D97706',
  '#B45309',
];
export const typeToColor = (type: string, hex = false) => {
  const idx = AIRCRAFT_TYPES_DATA.findIndex(v => v.value == type);
  if (hex) {
    return idx >= 0 ? hexColors[idx] : 'black';
  }
  return idx >= 0 ? colors[idx] : 'black';
};
export const timeData = (from = 0, to = 24, step = 15, suffix?: string) => {
  const data: { value: string; label: string }[] = [];
  let am_pm: 'AM' | 'PM' = 'AM';
  for (let hours = from; hours < to; hours++) {
    if (hours == 12) {
      am_pm = 'PM';
    }
    for (let min = 0; min < 60; min += step) {
      const mStr = `${min}`.padStart(2, '0');
      const hStr = `${hours > 12 ? hours % 12 : hours}`.padStart(2, '0');
      let value = `${hStr == '00' ? '12' : hStr}:${mStr} ${am_pm}`;
      if (suffix) {
        value = `${value} ${suffix}`;
      }
      data.push({ label: value, value });
    }
  }
  return data;
};
export const TIME_DATA = timeData();

export const elementInViewport = (el: Element) => {
  var bounding = el.getBoundingClientRect();

  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  ) {
    console.log('Element is in the viewport!');
    return true;
  } else {
    console.log('Element is NOT in the viewport!');
    return false;
  }
};

/**
 *  %div{:class => "flex justify-around"}
        %span{:class => "relative inline-flex rounded-md shadow-sm"}
          = current_user.abbreviated_name
          %i.fa.fa-angle-down
          - unless current_user.notifications.unread.size.zero?
            %span{:class => "ml-4 px-1 rounded-full"}
              = "(#{current_user.notifications.unread.size})"

            %span{:class => "flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-2"}
              %span{:class => "animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"}
              %span{:class => "relative inline-flex rounded-full h-3 w-3 bg-red-500"}
 */

export const countryCodeToName = (code: string) => {
  const found = COUNTRIES.find(c => c.value === code);
  if (found) {
    return found.label;
  }
  return code;
};
