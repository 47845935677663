import { Aircraft } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import Calendar from '@/components/Operator/Calendar';
import React from 'react';

type Props = { aircraft_availabilities: any; aircraft: Aircraft };

const AvailabilityCalendar: React.FC<Props> = props => {
  return (
    <ReactQueryProvider>
      <Calendar {...props} />;
    </ReactQueryProvider>
  );
};
export default AvailabilityCalendar;
