import { useMemo } from 'react';
import { useAddEmptyleg } from '@/API/aircraftApi';
import { Airport } from '@/API/airportApi';
import { Aircraft } from '@/API/models';
import { addDays, formatDate } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';

export const useAddEmptyLegForm = (aircraft: Aircraft, afterSubmit) => {
  const addEmptyleg = useAddEmptyleg();

  const onSubmit = async (values: typeof initialValues) => {
    const emptyLeg = {
      departure_airport: values.departure_airport,
      arrival_airport: values.arrival_airport,
      date1: `${formatDate(values.date_range.from, 'mm/dd/yyyy')}, ${
        values.date_range.from_time ?? '12:00 AM'
      }`,
      date2: `${formatDate(values.date_range.to, 'mm/dd/yyyy')}, ${
        values.date_range.to_time ?? '12:00 AM'
      }`,
      flightTimeDec: values.flight_time,
      price: values.price,
    };

    const data = await addEmptyleg.mutateAsync({
      aircraft_id: `${aircraft.id}`,
      emptyLeg,
    });
    afterSubmit();
  };

  const initialValues = useMemo(
    () => ({
      date_range: {
        from: new Date(),
        from_time: '',
        to: addDays(30),
        to_time: '',
      },
      flight_time: undefined,
      departure_airport: { airport_code: '' } as Airport,
      arrival_airport: { airport_code: '' } as Airport,
      price: undefined,
    }),
    [],
  );
  const validationSchema = useMemo(
    () =>
      yup.object({
        date_range: yup.object({
          from: yup.date().required(),
          to: yup.date().required(),
        }),
        flight_time: yup.number().optional(),
        departure_airport: yup.object({
          airport_code: yup.string().required(),
        }),
        arrival_airport: yup.object({
          airport_code: yup.string().required(),
        }),
        price: yup.number().positive().optional(),
      }),
    [],
  );

  return { initialValues, validationSchema, onSubmit };
};
