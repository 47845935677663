import { Toggle, Select } from '@/components/Forms';
import SavedRoutes, { SavedRoutesProps } from '@/components/SavedRoutes';
import React from 'react';

type Props = { frequencies: any } & SavedRoutesProps;

const ProfileSettings: React.FC<Props> = ({
  frequencies,
  ...savedRoutesProps
}) => {
  
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  
  return (
    <div className='py-10 w-full justify-center flex flex-wrap '>
      <p className='leading-relaxed text-2xl mb-8 text-center'>
        Tell us every route you’re interested in so we can send you special
        one-way charter opportunities and relevant SkyShare notifications.
      </p>
      <div className='w-full mb-16 flex flex-wrap'>
        <SavedRoutes {...savedRoutesProps} />
      </div>
      <Toggle
        name='receive_emptyleg_flight_notification'
        label='Empty Legs notifications'
        placeholder='receive notifications for relevant emptylegs'
        className='w-full lg:w-1/2'
      />
      <Select
        name='newsletter_frequency'
        label='Empty Legs notification frequency'
        data={frequencies}
        className='w-full lg:w-1/2'
      />
      <Toggle
        name='receive_shared_flight_notification'
        label='SkyShare notifications'
        placeholder='receive notifications for relevant SkyShare flights'
        className='w-full'
      />
    </div>
  );
};
export default ProfileSettings;
