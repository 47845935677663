import React, { ReactElement, useMemo } from 'react';

export type Step = {
  title: string;
  Content: ReactElement;
};

type Props = {
  steps: Step[];
  active: number;
  onClick?: (i: number) => void;
};

const Steps: React.FC<Props> = ({ steps, active, onClick }) => {
  const Tab = useMemo(() => steps[active], [steps, active]);

  return (
    <>
      <section className='text-gray-600 body-font'>
        <div className='container mx-auto flex flex-wrap flex-col'>
          <div className='flex mx-auto flex-wrap'>
            {steps.map((step, i) => {
              return (
                <div className='flex-center' key={i}>
                  {i > 0 && (
                    <span
                      className={active >= i ? ' bg-red-500' : 'bg-gray-500'}
                      style={{
                        height: '1px',
                        width: '20px',
                        overflow: 'hidden',
                      }}
                    ></span>
                  )}
                  <a
                    onClick={() => {
                      onClick?.(i);
                    }}
                    className={`cursor-pointer px-6 justify-center sm:justify-start title-font font-medium  inline-flex items-center leading-none  tracking-wider rounded-t ${
                      active == i ? ' text-red-500' : 'text-gray-500'
                    }`}
                  >
                    <span
                      className={`text-2xl rounded-full w-10 h-10 leading-10 text-center mx-4 ${
                        active == i
                          ? 'bg-red-500 text-white'
                          : active > i
                          ? 'bg-gray-500 text-white'
                          : 'border-1 border-solid border-current'
                      }`}
                    >
                      {i + 1}
                    </span>
                    <span
                      className={`hidden sm:inline-block ${
                        active == i
                          ? 'text-red-500'
                          : active > i
                          ? 'text-gray-500'
                          : ''
                      }`}
                    >
                      {step.title}
                    </span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className=''>{Tab.Content}</div>
    </>
  );
};
export default Steps;
