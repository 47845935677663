import { Polyline } from '@react-google-maps/api';
import React from 'react';

type Props = {
  emptyleg: {
    ac: {
      type: string;
      typeJson: {
        value: string;
        label: string;
        color: string;
      };
    };
    departureAirport?: google.maps.LatLngLiteral;
    arrivalAirport?: google.maps.LatLngLiteral;
  };
  highlighted?: boolean;
  onHover?: (e: google.maps.MapMouseEvent) => void;
};
const polylineOptions = {
  strokeOpacity: 1,
  strokeWeight: 2,
  geodesic: true,
};
const MapLine: React.FC<Props> = ({
  emptyleg,
  onHover,
  highlighted = false,
}) => {
  // 3 arrows on each line
  const icons = React.useMemo(
    () =>
      [0, 0.5, 1].map(a => ({
        offset: `${a * 100}%`,
        icon: {
          path:
            a == 0
              ? google.maps.SymbolPath.CIRCLE
              : google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
      })),
    [],
  );
  // const color = typeToColor(emptyleg.ac.type, true);
  return (
    <>
      <Polyline
        options={{
          ...polylineOptions,
          strokeColor: highlighted ? 'cyan' : emptyleg.ac.typeJson.color,
          icons,
        }}
        path={[
          {
            lng: emptyleg.departureAirport!.lng,
            lat: emptyleg.departureAirport!.lat,
          },
          {
            lng: emptyleg.arrivalAirport!.lng,
            lat: emptyleg.arrivalAirport!.lat,
          },
        ]}
        onMouseOver={onHover}
      />
    </>
  );
};
export default React.memo(MapLine);
