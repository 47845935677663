import { useFormikContext } from 'formik';
import React from 'react';
import {
  AirportInput,
  DateRangeInput,
  FormElement,
  Input,
  TimeInput,
  Toggle,
} from '@/components/Forms';
import { EventForm } from '@/types/AddEventForms';

type Props = {};

const TransientFields: React.FC<Props> = ({}) => {
  const { values } = useFormikContext<EventForm>();

  return (
    <>
      <AirportInput name='airport' />
      <DateRangeInput name='date_range' />
      <FormElement name='blocked_hours' label='blocked hours'>
        <div className='flex flex-wrap md:-mx-4'>
          <Toggle
            name='unavailable_hours'
            noLabel
            placeholder='unavailable hours'
          />
          {values.unavailable_hours && (
            <>
              <TimeInput
                name='unavailable_start'
                label='start'
                className='w-full md:w-1/2'
              />
              <TimeInput
                name='unavailable_end'
                label='end'
                className='w-full md:w-1/2'
              />
            </>
          )}
          <Toggle name='event_mode' noLabel placeholder='Event Mode' />
          {values.event_mode && <Input name='event_title' />}
        </div>
      </FormElement>
    </>
  );
};
export default TransientFields;
