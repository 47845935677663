import ReactQueryProvider from '@/API/ReactQueryProvider';
import { StripeAccountType } from '@/API/stripeApi';
import StripeAccountDetails, {
  StripeAccountDetailsProps,
} from '@/components/Operator/Stripe/StripeAccountDetails';
import StripeButton from '@/components/Operator/Stripe/StripeButton';
import React, { useState } from 'react';

type Props = StripeAccountDetailsProps;

const StripeAccount: React.FC<Props> = ({
  account: data,
  status,
  link = '#',
}) => {
  const [account, setAccount] = useState<StripeAccountType>(data);

  return (
    <ReactQueryProvider>
      {/* <FboModal
        google_maps_api_key='AIzaSyBs9xwpOXEo1tR8NdQ-jE94oFpY5W7-jDE'
        name='fbo'
      /> */}
      {account ? (
        <StripeAccountDetails account={account} link={link} status={status} />
      ) : (
        <div className='flex-center w-full flex-col h-96'>
          <h1 className='text-2xl mb-8'>No stripe account found</h1>
          <StripeButton href={link}>Create an account</StripeButton>
        </div>
      )}
    </ReactQueryProvider>
  );
};
export default StripeAccount;
