import { Flight } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import {
  AirportInput,
  DateInput,
  Input,
  PhoneInput,
  SubmitBtn,
  TextAreaInput,
  TimeInput,
  Toggle,
} from '@/components/Forms';
import Modal from '@/components/Modal';
import Popover from '@/components/Popover';
import { useCheckAvailabilityForm } from '@/Utils/formHooks/useCheckAvailabilityForm';
import { useGetSeatPrice } from '@/Utils/formHooks/useGetSeatPrice';
import { strDate } from '@/Utils/helpers';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FiInfo } from 'react-icons/fi';

type Props = {
  flight: Flight & {
    action: string;
    bookingType: 'skyshare' | 'skylimo';
    phone?: string;
    enable_send_sms?: boolean;
    original_price?: number;
    pricing: {
      [key: number]: number;
    };
    price_config: {
      evolux_margin: number;
      passenger_margins: {
        [key: number]: number;
      };
    };
  };
};

const CheckAvailability: React.FC<Props> = ({ flight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const checkAvailabilityForm = useCheckAvailabilityForm(flight, setIsOpen);

  return (
    <div>
      <ReactQueryProvider>
        <Modal isOpen={isOpen}>
          <div className='flex flex-col justify-center items-center '>
            <p className='w-full p-10'>
              Your Availability Inquiry for this Empty Leg has been sent to the
              aircraft operator. You will be notified as soon as they respond to
              your inquiry.
            </p>
            <a className='btn btn-red' href='/search_emptylegs'>
              Back to search page
            </a>
          </div>
        </Modal>
        <h2 className='text-4xl font-black mb-10 pl-4'>
          {flight.bookingType == 'skylimo' ? 'SkyPrivate' : 'SkyShare'} -
          Customize your flight request
        </h2>
        <Formik {...checkAvailabilityForm}>
          {({ resetForm, setValues, values }) => {
            const seatPrice = useGetSeatPrice({
              price: values.asked_customer_price,
              price_config: flight.price_config,
              nbr_passengers: values.nbr_passengers,
              pricing: flight.pricing,
            });

            return (
              <Form className='flex flex-wrap'>
                <DateInput
                  className='md:w-1/2 w-full'
                  name='departure_date'
                  label='departure date'
                  dateRange={
                    flight.departureDateLatest !== undefined
                      ? {
                          from: strDate(flight.departureDate)!,
                          to: strDate(flight.departureDateLatest)!,
                        }
                      : undefined
                  }
                />
                <TimeInput
                  className='md:w-1/2 w-full md:pl-5'
                  name='departure_time'
                  label='departure time'
                  text={values.timezone}
                />
                {/* <Input
                  name='timezone'
                  className='md:w-1/4 w-full md:pl-5 opacity-50'
                  disabled
                /> */}
                <AirportInput
                  className='w-full'
                  name='departure_airport_code'
                  label='departure'
                />
                <AirportInput
                  className='w-full'
                  name='arrival_airport_code'
                  label='arrival'
                />
                <Input
                  className='w-full'
                  type='number'
                  name='nbr_passengers'
                  label={`anticipated number of passengers max( ${flight.capacity} )`}
                  placeholder='anticipated number of passengers'
                />
                <div className='w-full flex justify-center items-center'>
                  <Input
                    className=''
                    name='asked_customer_price'
                    label={
                      !checkAvailabilityForm.initialValues.asked_customer_price
                        ? 'name your price ( all in total emptyleg charter price )'
                        : 'posted price'
                    }
                    preText='$.'
                    placeholder='enter a price suggestion'
                    disabled={
                      !!checkAvailabilityForm.initialValues.asked_customer_price
                    }
                    info={
                      flight.bookingType !== 'skyshare'
                        ? 'You are negotiating the price for the entire aircraft.'
                        : 'You are negotiating the price for the entire aircraft. You will be able to set seat price parameters and invite others to your crowdsoured flight ,once a final price is negotiated'
                    }
                  />
                  {flight.original_price &&
                    flight.price !== flight.original_price && (
                      <Popover
                        Btn={() => (
                          <FiInfo size={20} className='mx-4 text-gray-400' />
                        )}
                        content={`converted price from ${flight.original_price}`}
                      />
                    )}
                </div>
                {flight.bookingType == 'skyshare' && (
                  <>
                    <Input
                      className='w-full'
                      name='seat_price'
                      label='anticipated seat price'
                      preText='$.'
                      disabled
                      value={seatPrice}
                      info='the seat price based on the total price and the number of passengers'
                      placeholder='based on the total price and the number of passengers'
                    />
                    {!checkAvailabilityForm.initialValues
                      .asked_customer_price && (
                      <p className='pl-4 -mt-4 mb-4 mx-4 w-full leading-relaxed bg-yellow-200 p-4 font-bold '>
                        This is only an estimate of the final seat price
                      </p>
                    )}
                  </>
                )}
                <TextAreaInput
                  className='w-full'
                  label='comments or special requests'
                  name='check_availability_reason'
                />
                <Toggle
                  name='enable_send_sms'
                  label='SMS notification'
                  placeholder='receive an sms notification'
                />
                <p className='pl-4 w-full leading-relaxed '>
                  Empty Legs are time-sensitive in nature, and since emails can
                  easily get buried in your in-box, opt-in here to receive an
                  SMS when the operator responds to your availability inquiry.
                </p>
                {values.enable_send_sms && (
                  <PhoneInput name='phone' label='phone number' />
                )}
                <button
                  type='reset'
                  className='sm:w-1/6 w-full btn btn-dimmed mb-8'
                  onClick={React.useCallback(() => {
                    resetForm();
                    setValues(checkAvailabilityForm.initialValues);
                  }, [
                    resetForm,
                    setValues,
                    checkAvailabilityForm.initialValues,
                  ])}
                >
                  reset
                </button>
                <SubmitBtn
                  text='Check availability'
                  className='w-full sm:w-5/6'
                />
                <p className='px-4 py-2 w-full mt-5 bg-gray-100 '>
                  this will be sent to the operator who can choose to accept or
                  modify it.
                </p>
              </Form>
            );
          }}
        </Formik>
      </ReactQueryProvider>
    </div>
  );
};
export default CheckAvailability;
