import { Input, PhoneInput, Select } from '@/components/Forms';
import AddressInput from '@/components/Forms/AddressInput';
import React, { useMemo } from 'react';

export type ProfileInfoProps = {
  timeZones: string[];
  google_maps_api_key: string;
};

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  timeZones,
  google_maps_api_key,
}) => {
  const timeZoneData = useMemo(() => {
    return timeZones.map(t => ({ label: t[0], value: t[1] }));
  }, [timeZones.length]);

  return (
    <div className='pt-10 w-full justify-center flex flex-wrap '>
      <Input className='w-full md:w-1/2' name='first_name' />
      <Input className='w-full md:w-1/2' name='last_name' />
      <Input className='w-full md:w-1/2' name='username' />
      <Input className='w-full md:w-1/2' name='email' />
      <Input
        className='w-full'
        name='date_of_birth'
        label='date of birth'
        placeholder='mm/dd/yyyy'
      />
      <Input className='w-full md:w-1/2' name='password' type='password' />
      <Input
        className='w-full md:w-1/2'
        name='password_confirmation'
        label='confirm password'
        type='password'
      />
      <AddressInput name='address' google_maps_api_key={google_maps_api_key} />
      <PhoneInput name='phone' label='phone number' className='w-full' />
      <Input className='w-full md:w-1/2' name='height' />
      <Input className='w-full md:w-1/2' name='weight' text=' lbs.' />
      <Select data={timeZoneData} name='time_zone' label='time zone' />
    </div>
  );
};
export default ProfileInfo;
