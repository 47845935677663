import { Form } from 'formik';
import React, { useState } from 'react';
import { FiChevronsDown, FiChevronsUp, FiSearch } from 'react-icons/fi';
import {
  AirportInput,
  DateRangeInput,
  DebouncedInput,
  FormElement,
  Select,
  SubmitBtn,
  Toggle,
} from '@/components/Forms';
import { AIRCRAFT_TYPES_DATA, AMENITIES, SAFETY } from '@/Utils/constants';
import useMediaQuery from '@/Utils/useMediaQuery';
import useOnScroll from '@/Utils/useOnScroll';

const FloatingSearchBtn = () => {
  const [visible, setvisible] = useState(true);
  useOnScroll(() => {
    if (isFormVisible()) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  });
  const isFormVisible = () => {
    var searchForm = document.getElementById('search-form')!;
    var bounding = searchForm.getBoundingClientRect();
    const screenHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return (
      bounding.top >= 0 ||
      (bounding.bottom >= 0 && bounding.bottom <= screenHeight)
    );
  };

  return (
    <button
      className={`fixed flex top-5  inset-x-0 mx-auto px-10 py-5 items-center
      z-20 rounded-lg border-none bg-white bg-opacity-95  text-2xl shadow-md
      
      ${visible ? 'hidden' : 'block animate-drop'}
      `}
      onClick={() => {
        document.getElementById('search-form')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }}
    >
      Search
      <FiSearch className='ml-5' size={20} />
    </button>
  );
};

const Filters: React.FC<{ amenities: string[] }> = ({ amenities }) => {
  return (
    <div className='flex flex-wrap md:-mx-4'>
      <Select
        className='w-full md:w-1/3'
        noLabel
        name='type'
        placeholder='filter by type'
        data={AIRCRAFT_TYPES_DATA}
        isMulti
      />
      <Select
        className='w-full md:w-1/3'
        noLabel
        placeholder='filter by amenities'
        name='amenities'
        data={amenities.length > 0 ? amenities : AMENITIES}
        isMulti
      />
      <Select
        className='w-full md:w-1/3'
        noLabel
        disabled
        placeholder='filter by safety rating (coming soon)'
        name='safetyRating'
        data={SAFETY}
        isMulti
      />
      <Toggle
        name='price'
        placeholder='only show with prices'
        className='w-full md:w-1/3'
      />
      <Toggle
        name='evolux_only'
        placeholder='only show created on evolux'
        className='w-full md:w-1/3'
      />
    </div>
  );
};
type Props = { amenities: string[] };

const SearchForm: React.FC<Props> = ({ amenities }) => {
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <>
      {isMobile && <FloatingSearchBtn />}
      <Form id='search-form' className='mx-auto flex flex-wrap p-4'>
        <AirportInput className='w-full md:w-2/5' name='from' />
        <AirportInput className='w-full md:w-2/5' name='to' />
        <DebouncedInput
          name='radius'
          label='radius: 0-300 miles'
          text='miles'
          type='number'
          min='0'
          max='300'
          className='w-full md:w-1/5'
        />
        <DateRangeInput name='dateRange' buttons={[0, 7, 30]} />
        {isMobile && (
          <button
            className='w-full flex items-center justify-center btn btn-dimmed'
            type='button'
            onClick={() => setShowFilters(v => !v)}
          >
            <span className='mr-4'>Filters</span>
            {showFilters ? (
              <FiChevronsUp size={20} />
            ) : (
              <FiChevronsDown size={20} />
            )}
          </button>
        )}
        <FormElement
          name='filters'
          className={`${
            !isMobile
              ? ''
              : showFilters
              ? 'animate-slide-down'
              : 'animate-slide-up'
          } my-6`}
        >
          <Filters amenities={amenities} />
        </FormElement>
        <SubmitBtn text='Search' />
      </Form>
    </>
  );
};
export default SearchForm;
