import React, { useCallback, useEffect, useRef } from 'react';

type Props = {
  errors?: string[];
  signIn?: boolean;
};

const ErrorsTooltip: React.FC<Props> = ({ errors, signIn = false }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!errors?.length || !ref.current) {
      return;
    }
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, [errors, ref.current]);

  const errorMessage = useCallback(
    (err: string) =>
      signIn && err.includes('already been taken')
        ? `${err} - please try signing in or resetting password.`
        : err,
    [signIn],
  );
  if (!errors) return null;
  return (
    <div ref={ref} className='flex gap-4 w-full flex-col mb-4'>
      {errors.map((err, i) => (
        <div
          key={i}
          className='bg-red-200 border-2 border-solid border-red-400 p-4 rounded-md'
        >
          {errorMessage(err)}
        </div>
      ))}
    </div>
  );
};
export default ErrorsTooltip;
