import { FormElement, InputProps } from '@/components/Forms';
import Modal from '@/components/Modal';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

type Props = InputProps & { google_maps_api_key: string };

const FboForm: React.FC<Props> = ({ google_maps_api_key, ...props }) => {
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: google_maps_api_key,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (!place.address_components) return;
      console.log({ place });
    },
    options: {
      fields: ['address_components'],
      // types: ['address'],
      types: ['establishment'],
    },
  });
  return (
    <Form>
      <FormElement {...props} label='address'>
        <div className='flex items-baseline'>
          <input
            ref={ref}
            className='placeholder-gray-500'
            style={{ ...props.style }}
            placeholder={props.placeholder || 'Search for a location'}
            type={props.type || 'text'}
            id={props.name}
            {...props}
          />
        </div>
        <ErrorMessage name={props.name} />
      </FormElement>
    </Form>
  );
};

const FboModal: React.FC<Props> = props => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {/* <Formik initialValues={{ fbo: '' }} onSubmit={() => {}}>
        <Form>
          <FormElement {...props}>
            <div className='flex items-baseline'>
              <input
                ref={ref}
                className='placeholder-gray-500'
                style={{ ...props.style }}
                placeholder={props.placeholder || 'Search for a location'}
                type={props.type || 'text'}
                id={props.name}
                {...props}
              />
            </div>
            <ErrorMessage name={props.name} />
          </FormElement>
        </Form>
      </Formik> */}
      <button
        className='cursor-pointer my-5 px-10 py-5 rounded-md border-none text-xl font-black bg-gray-200 hover:bg-gray-400 text-gray-900'
        onClick={() => setOpen(true)}
      >
        Select a departure FBO
      </button>
      <Formik initialValues={{ fbo: '' }} onSubmit={() => {}}>
        <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
          <h1 className='text-4xl pb-8 px-4'>Please Select an FBO</h1>
          <FboForm {...props} />
        </Modal>
      </Formik>
    </>
  );
};
export default FboModal;
