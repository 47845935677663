import { Route } from '@/API/userApi';
import React from 'react';
import { FaPlane } from 'react-icons/fa';
import { FiRepeat, FiTrash2 } from 'react-icons/fi';

type Props = Route & { onRemove: () => void };

const SavedRouteCard: React.FC<Props> = ({
  departure_airport,
  arrival_airport,
  returned,
  radius,
  id,
  onRemove,
}) => (
  <div
    key={id}
    className='mt-4 flex rounded-lg w-full bg-indigo-200 shadow-md capitalize text-3xl items-center justify-between p-8 box-border'
  >
    <p className='flex flex-1 w-3/4 justify-around items-center flex-wrap m-0 text-center'>
      <span className='w-full sm:w-1/4'>
        {`${departure_airport.airport_code} (${departure_airport.location})`}
      </span>
      <span className='flex-1 font-bold text-blue-900 flex-center'>
        {returned ? (
          <FiRepeat className='mx-4' size={20} />
        ) : (
          <FaPlane className='mx-4 rotate-90 sm:rotate-0 transform' />
        )}
        ({radius}miles)
      </span>
      <span className='w-full sm:w-1/4'>
        {`${arrival_airport.airport_code} (${arrival_airport.location})`}
      </span>
    </p>
    <FiTrash2
      size={20}
      className='text-red-500 cursor-pointer'
      onClick={onRemove}
    />
  </div>
);
export default SavedRouteCard;
