import React, { useState } from 'react';
import { Aircraft } from '@/API/models';
import Modal from '@/components/Modal';
import AddEventModal from '@/components/Operator/Calendar/AddEventModal';
import CalendarEvent from '@/components/Operator/Calendar/CalendarEvent';
import EventDetails from '@/components/Operator/Calendar/EventDetails';
import { INITIAL_EVENTS } from '@/Utils/event-utils';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
// import AddEventModal from './AddEventModal';
import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  formatDate,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import useMediaQuery from '@/Utils/useMediaQuery';

type Props = { aircraft_availabilities: any; aircraft: Aircraft };

const Calendar: React.FC<Props> = ({ aircraft }) => {
  const [weekends, setWeekends] = useState(true);
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [info, setInfo] = useState<DateSelectArg>();
  const [selected, setSelected] = useState<EventClickArg>();
  const isMobile = useMediaQuery('(max-width: 640px)');

  const renderSidebar = () => {
    return (
      <>
        <div>
          <h2>Aircraft:{aircraft.tail_number}</h2>
          <div>
            <h2>Instructions</h2>
            <ul>
              <li>
                Select dates and you will be prompted to create a new event
              </li>
              <li>Drag, drop, and resize events</li>
              <li>Click an event to delete it</li>
            </ul>
          </div>
          <div>
            <button
              onClick={() => {
                selected?.event.remove();
                setSelected(undefined);
              }}
            >
              Delete
            </button>
          </div>
          {/* <div className='demo-app-sidebar-section'>
						<h2>All Events ({currentEvents.length})</h2>
						<ul>{currentEvents.map(renderSidebarEvent)}</ul>
					</div> */}
        </div>
      </>
    );
  };

  const handleWeekendsToggle = () => {
    setWeekends(w => !w);
  };

  const handleDateSelect = (selectInfo?: DateSelectArg) => {
    setInfo(selectInfo);
    setOpenAddModal(true);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelected(clickInfo);
    // if (
    // 	window.confirm(
    // 		`Are you sure you want to delete the event '${clickInfo.event.title}'`,
    // 	)
    // ) {
    // 	clickInfo.event.remove();
    // }
  };

  const handleEvents = (events: EventApi[]) => {
    setCurrentEvents(events);
  };
  return (
    <div>
      <AddEventModal
        info={info}
        modalProps={{
          isOpen: openAddModal,
          onRequestClose: () => setOpenAddModal(false),
          portalClassName: 'my-portal',
        }}
      />
      <Modal
        isOpen={selected !== undefined}
        onRequestClose={() => setSelected(undefined)}
      >
        <EventDetails
          event={selected?.event}
          onClose={() => setSelected(undefined)}
        />
      </Modal>
      <div className='demo-app-main'>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today title',
            center: 'Add',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          customButtons={{
            Add: { text: 'Add', click: () => handleDateSelect() },
          }}
          initialView={isMobile ? 'timeGridDay' : 'dayGridMonth'}
          editable={true}
          selectable={true}
          selectMirror={true}
          eventOverlap={true}
          selectOverlap={true}
          weekends={weekends}
          eventClassNames={e => {
            let className = 'p-5 text-3xl';
            if (e.event.allDay) {
              className += ' rounded-3xl shadow-md';
            } else {
              className += ' rounded-tr-3xl';
            }
            return className;
          }}
          initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventContent={CalendarEvent} // custom render function
          eventClick={handleEventClick}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        */
        />
      </div>
    </div>
  );
};

function renderSidebarEvent(event: EventApi) {
  return (
    <li key={event.id}>
      <b>
        {formatDate(event.start!, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </b>
      <i>{event.title}</i>
    </li>
  );
}
export default Calendar;
