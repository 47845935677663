import { User } from '@/API/models';
import {
  ErrorMessage,
  FormElement,
  Input,
  InputProps,
} from '@/components/Forms';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

type Props = InputProps & { google_maps_api_key: string };

const AddressInput: React.FC<Props> = ({ google_maps_api_key, ...props }) => {
  const { setFieldValue, values } = useFormikContext<User>();
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();

  const fillInAddress = useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (!place.address_components) return;
      setPlace(place);
      let streetAddress = '';
      let postcode = '';
      for (const component of place.address_components) {
        switch (component.types[0]) {
          case 'street_number': {
            streetAddress = `${component.long_name} ${streetAddress}`;
            break;
          }

          case 'route': {
            streetAddress += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case 'locality':
            setFieldValue('city', component.long_name);
            break;

          case 'administrative_area_level_1': {
            setFieldValue('state', component.short_name);
            break;
          }

          case 'country':
            setFieldValue('country', component.long_name);
            break;
        }
      }
      setFieldValue('street', streetAddress);
      setFieldValue('postal_code', postcode);
    },
    [setFieldValue],
  );

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: google_maps_api_key,
    onPlaceSelected: fillInAddress,
    options: {
      fields: ['address_components'],
      types: ['address'],
    },
  });

  return (
    <>
      <FormElement {...props}>
        <div className='flex items-baseline'>
          <input
            ref={ref}
            className='placeholder-gray-500'
            style={{ ...props.style }}
            placeholder={props.placeholder || 'Search for a location'}
            type={props.type || 'text'}
            id={props.name}
            disabled={props.disabled}
          />
        </div>
        <ErrorMessage name={props.name} />
      </FormElement>
      {(place ||
        values.country ||
        values.city ||
        values.state ||
        values.street) && (
        <>
          <Input className='w-full md:w-1/2' name='country' />
          <Input className='w-full md:w-1/2' name='state' />
          <Input
            className='w-full md:w-1/3'
            name='street'
            label='street address'
          />
          <Input className='w-full md:w-1/3' name='city' />
          <Input
            className='w-full md:w-1/3'
            name='postal_code'
            label='zip code'
          />
        </>
      )}
    </>
  );
};

export default AddressInput;
