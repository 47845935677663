import {
  Route,
  useAddRoute,
  useDeleteRoute,
  useGetUserRoutes,
} from '@/API/userApi';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

type Args = {
  // in signup routes are not added to the database in realtime
  isSignup?: boolean;
};

export const useSavedRoutesForm = ({ isSignup = false }: Args) => {
  const {
    values: { savedRoutesForm, saved_routes },
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<{
    savedRoutesForm: Route;
    saved_routes: Route[];
  }>();
  // add routes to cache
  const { data: AllRoutes } = useGetUserRoutes(saved_routes);
  const deleteRouteApi = useDeleteRoute();
  const addRouteApi = useAddRoute();
  const deleteRoute = useMemo(
    () => async (id: number) => {
      if (!isSignup) {
        await deleteRouteApi.mutateAsync(id);
      }
    },
    [deleteRouteApi],
  );
  const addRoute = useMemo(
    () => async (push: (route: Route) => void) => {
      const allRoutes = saved_routes ?? [];
      const { departure_airport, arrival_airport, radius } = savedRoutesForm;
      const dep = departure_airport?.airport_code;
      const arr = arrival_airport?.airport_code;

      if (!dep)
        setFieldError(
          'savedRoutesForm.departure_airport.airport_code',
          'required',
        );
      if (!arr)
        setFieldError(
          'savedRoutesForm.arrival_airport.airport_code',
          'required',
        );
      const isRadiusError = !radius || radius > 300 || radius < 1;
      if (isRadiusError)
          setFieldError('savedRoutesForm.radius', 'must be a number 1-300');

      if (!dep || !arr || isRadiusError) return;

      // same route already added
      const match = allRoutes.find(
        route =>
          route.arrival_airport.airport_code == arr &&
          route.departure_airport.airport_code == dep,
      );
      // reverse route already added
      const reverse = allRoutes.find(
        route =>
          route.arrival_airport.airport_code == dep &&
          route.departure_airport.airport_code == arr,
      );

      if (match || (reverse && reverse.returned)) {
        setFieldError(
          'savedRoutesForm.departure_airport.airport_code',
          'route already added',
        );
        setFieldError(
          'savedRoutesForm.arrival_airport.airport_code',
          'route already added',
        );
        return;
      }

      if (reverse && !reverse.returned) {
        // delete old route
        deleteRoute(reverse.id!);
        // add the new one with return trip
        savedRoutesForm.returned = true;
      }

      try {
        if (!isSignup) {
          await addRouteApi.mutateAsync(savedRoutesForm);
        }
        push(savedRoutesForm);
        setFieldValue('savedRoutesForm.departure_airport', {});
        setFieldValue('savedRoutesForm.arrival_airport', {});
      } catch (error) {
        console.log('error adding route');
      }
    },
    [savedRoutesForm, setFieldValue, setFieldTouched, deleteRoute],
  );

  return {
    saved_routes: isSignup ? saved_routes : AllRoutes,
    addRoute,
    deleteRoute,
  };
};
