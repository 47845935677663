import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';
import useTrace from '@/Utils/useTrace';
import { useField } from 'formik';
import React, { useEffect } from 'react';
import ReactPhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type Props = InputProps;

const PhoneInput: React.FC<Props> = props => {
  const [formik, {}, { setValue, setError, setTouched }] = useField(props);

  return (
    <FormElement {...props}>
      <div className='flex items-baseline'>
        {props.preText && (
          <p className='pr-1 inline-block text-gray-700 font-bold'>
            {props.preText}
          </p>
        )}
        <ReactPhoneInput
          defaultCountry='US'
          placeholder={props.placeholder || '....'}
          disabled={props.disabled}
          {...formik}
          onChange={value => {
            setValue(value);
          }}
          className='w-full'
          // containerClass='w-full rounded-none'
          // inputClass='w-full rounded-none'
        />
        {props.children}
      </div>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
// const PhoneInput: React.FC<Props> = props => {
//   const [formik, {}, { setValue }] = useField(props);

//   return (
//     <FormElement {...props}>
//       <div className='flex items-baseline'>
//         {props.preText && (
//           <p className='pr-1 inline-block text-gray-700 font-bold'>
//             {props.preText}
//           </p>
//         )}
//         <ReactPhoneInput
//           country='us'
//           placeholder={props.placeholder || '....'}
//           disabled={props.disabled}
//           {...formik}
//           onChange={(value, _data, _e, _formatted) => {
//             console.log({ value, _formatted });
//             setValue(value);
//           }}
//           containerClass='w-full rounded-none'
//           inputClass='w-full rounded-none'
//         />
//         {props.children}
//       </div>
//       <ErrorMessage name={props.name} />
//     </FormElement>
//   );
// };
export default PhoneInput;
