import ErrorsTooltip from '@/components/ErrorsTooltip';
import { Captcha, SubmitBtn, Toggle } from '@/components/Forms';
import { SelectDataEntry } from '@/components/Forms/Select';
import Modal from '@/components/Modal';
import ProfileInfo from '@/components/Profile/ProfileInfo';
import ProfileSettings from '@/components/Profile/ProfileSettings';
import Steps from '@/components/Steps';
import { RegisterProps } from '@/pages/Register';
import { useRegisterForm } from '@/Utils/formHooks/useRegisterForm';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';

const RegisterForm: React.FC<RegisterProps> = ({
  newsletter_frequencies,
  google_maps_api_key,
  timeZones,
  action,
  recaptchaKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentStep, handleTabClick, errors, ...registerForm } =
    useRegisterForm(() => {
      setIsOpen(true);
    });

  const frequencies = useMemo(() => {
    // transforming object to an array
    let array: SelectDataEntry[] = [];
    for (const key in newsletter_frequencies) {
      if (Object.prototype.hasOwnProperty.call(newsletter_frequencies, key)) {
        const element = newsletter_frequencies[key];
        array = [...array, { label: key, value: element }];
      }
    }
    return array;
  }, [newsletter_frequencies]);

  const tabs = [
    {
      title: 'Profile Settings',
      Content: (
        <>
          <ProfileInfo {...{ google_maps_api_key, timeZones }} />
          <div className='flex-center justify-start relative'>
            <Toggle
              name='agree_on_terms'
              className='w-auto'
              label='terms of service'
              placeholder='I agree to EvoLux’s'
            />
            <a
              href='/terms'
              target={'_blank'}
              className='font-bold'
              style={{ position: 'absolute', left: '165px', top: '31px' }}
            >
              terms of service
            </a>
          </div>
        </>
      ),
    },
    {
      title: 'Notification Settings',
      Content: (
        <>
          <ProfileSettings {...{ frequencies, isSignup: true }} />
          <Captcha api={recaptchaKey} />
        </>
      ),
    },
  ];

  return (
    <>
      <Modal isOpen={isOpen}>
        <div className='flex flex-col justify-center items-center'>
          <p className='w-full p-10 md:p-20 text-center text-3xl'>
            Your Account was created but you still need to confirm it, in order
            to continue please click on the confirmation link that was just sent
            to your email.
          </p>
          <a className='btn btn-red' href='/users/sign_in'>
            Go to the sign in page
          </a>
        </div>
      </Modal>
      <Formik {...registerForm}>
        {() => (
          <Form className='w-11/12 justify-center lg:w-3/5 flex flex-wrap mx-auto'>
            {/* <ProfilePicture url={undefined} /> */}
            <ErrorsTooltip errors={errors} signIn />
            <Steps steps={tabs} active={currentStep} onClick={handleTabClick} />
            <div className=' py-10 w-full justify-center flex flex-wrap '>
              <SubmitBtn
                text={currentStep == 0 ? 'Next Step' : 'Create Account'}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default RegisterForm;
