import React from 'react';
import { Flight } from '@/API/models';
import { formatMoney } from '@/Utils/helpers';

export type DisplayFlightProps = Flight & {
  departureAirportTime?: string;
  displayDepTime: string;
  displayArrTime: string;
  reason: string;
  title: string;
  modified: string[];
  isOperatorChanges?: boolean;
};

const DisplayFlight: React.FC<DisplayFlightProps> = ({
  price,
  fees,
  title,
  departureCity,
  arrivalCity,
  departureCode,
  departureDate,
  departureTime,
  arrivalTime,
  arrivalCode,
  flightTime,
  nbrPassengers,
  reason,
  currency,
  modified,
  departureAirportTime,
  displayArrTime,
  displayDepTime,
  isOperatorChanges = false,
}) => {
  const highlightModified = (key: keyof DisplayFlightProps) => {
    if (!modified.includes) return '';
    return modified.includes(key) ? 'text-indigo-500 text-2xl font-bold' : '';
  };

  const textIfModified = (key: string, normal: string, ifModified: string) => {
    if (!modified.includes) return normal;
    if (!isOperatorChanges) {
      return normal;
    }

    return modified.includes(key) ? ifModified : normal;
  };

  return (
    <div className='trip'>
      <div className='title'>
        {title ?? 'One-Way Empty Leg Charter Special (Modified)'}
      </div>
      <div className='multidate'>
        <dl className='flights'>
          {/* <dt>Sun, Aug 29</dt> */}
          <dt>
            <span className={`${highlightModified('departureDate')}`}>
              {departureDate}
            </span>
            ,
            <span className={`ml-2 ${highlightModified('departureTime')}`}>
              {departureTime}
            </span>
          </dt>
          <dt>departure airport local timezone: {departureAirportTime}</dt>
          <dd>
            <ul className='times'>
              <li>
                <mark className={`${highlightModified('departureCode')}`}>
                  {departureCode}
                </mark>
                <p>{departureCity}</p>
                <p>{displayDepTime}</p>
              </li>
              <li className='to'>
                <i className='fa fa-angle-right'></i>
                <p
                  className={`mx-6 text-gray-600 ${highlightModified(
                    'departureCode',
                  )}`}
                >
                  {`${flightTime}h`}
                </p>
              </li>
              <li>
                <mark className={`${highlightModified('arrivalCode')}`}>
                  {arrivalCode}
                </mark>
                <p>{arrivalCity}</p>
                <p>{displayArrTime}</p>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      {fees?.length > 0 && (
        <dl className='subtotal text-black font-bold'>
          <dt>Cost breakdown of additional fees</dt>
        </dl>
      )}
      {fees.map(fee => (
        <dl key={fee.id} className={`subtotal pl-20`}>
          <li>{fee.name}</li>
          <dt>{formatMoney(fee.amount, fee.currency)}</dt>
        </dl>
      ))}
      <dl className={`subtotal text-black font-bold`}>
        <dt>
          {textIfModified(
            'price',
            'Requested Price',
            'Operator Adjusted Price',
          )}
        </dt>
        {/* <dd>$1,700.01</dd> */}
        <dt className={`${highlightModified('price')}`}>
          {formatMoney(price, currency)}
        </dt>
      </dl>
      <dl className='subtotal'>
        <dt>
          {textIfModified(
            'nbrPassengers',
            'Anticipated Number Of Passengers',
            'Operator Adjusted Number Of Passengers',
          )}
        </dt>
        <dt
          className={`${highlightModified('nbrPassengers')}`}
        >{`${nbrPassengers} person(s)`}</dt>
      </dl>
      {reason && (
        <>
          <dl className='subtotal text-black font-bold'>
            <dt>Comments</dt>
          </dl>
          <dl className='subtotal'>
            <dt className={`p-4 ${highlightModified('reason')}`}>
              Reason: {reason}
            </dt>
            <dt></dt>
          </dl>
        </>
      )}
    </div>
  );
};
export default DisplayFlight;
