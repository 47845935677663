import {
  Notification,
  useGetNotifications,
  useMarkAsRead,
} from '@/API/notificationsApi';
import Modal from '@/components/Modal';
import React, { useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';

type Props = {
  notifications: Notification[];
};

const NotificationList: React.FC<Props> = props => {
  const { data } = useGetNotifications(props.notifications);
  const markAsRead = useMarkAsRead();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<Notification | null>(null);

  return (
    <>
      <Modal
        isOpen={open && !!selected}
        onRequestClose={() => {
          setOpen(false);
          setSelected(null);
        }}
      >
        {selected && (
          <div dangerouslySetInnerHTML={{ __html: selected.content }}></div>
        )}
      </Modal>
      <div className='w-full min-h-screen'>
        {!data?.length ? (
          <p className='text-3xl'>No notifications</p>
        ) : (
          data.map(n => (
            <button
              onClick={() => {
                setSelected(n);
                setOpen(true);
                if (!n.read) {
                  markAsRead.mutate(n.id);
                }
              }}
              key={n.id}
              className={`w-full border-none text-left cursor-pointer transition-all transform translate-x-0 hover:translate-x-5 flex-1 px-8 py-6 my-5 bg-gray-200 shadow-md flex flex-row items-center justify-between text-indigo-300 ${
                n.read ? 'opacity-50' : ''
              }`}
            >
              <h2 className='text-gray-900 font-medium title-font capitalize mb-0 w-1/4 overflow-hidden overflow-ellipsis'>
                {n.subject}
                <p className='text-lg leading-relaxed text-gray-500 mb-2'>
                  Learn More
                </p>
              </h2>
              <p className='leading-relaxed text-gray-500 mb-2'>{n.sentAt}</p>
              <a
                onClick={e => {
                  e.stopPropagation();
                  if (!n.read) {
                    markAsRead.mutate(n.id);
                  }
                }}
                href={n.onClickUrl}
                className='text-indigo-500 flex items-center btn btn-dimmed ring-1 ring-white w-max'
              >
                Take Action
                <FiArrowRight />
              </a>
            </button>
          ))
        )}
      </div>
    </>
  );
};
export default NotificationList;
