import { Airport } from '@/API/airportApi';
import api from '@/API/config';
import { Flight } from '@/API/models';
import { formatDate, strDate } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';
import { FormikHelpers, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

type CheckAvailabilityFlight = Flight & {
  action: string;
  bookingType: 'skyshare' | 'skylimo';
  phone?: string;
  enable_send_sms?: boolean;
};

export const useCheckAvailabilityForm = (
  flight: CheckAvailabilityFlight,
  setIsOpen: (open: boolean) => void,
) => {
  const initialValues = useMemo(() => {
    const time = flight.departureTime;
    const [hh_mm, am_pm, timezone] = time.split(' ');
    
    const initialPhone = flight.phone ?? '';
    const validPhone = isValidPhoneNumber(initialPhone) ? initialPhone : '';
    return {
      departure_date: strDate(flight.departureDate),
      timezone,
      departure_time: [hh_mm, am_pm].join(' '),
      departure_airport_code: { airport_code: flight.departureCode } as Airport,
      arrival_airport_code: { airport_code: flight.arrivalCode } as Airport,
      asked_customer_price: flight.price > 1 ? flight.price : undefined,
      check_availability_reason: '',
      nbr_passengers: 1,
      enable_send_sms: flight.enable_send_sms,
      phone: validPhone,
    };
  }, [flight]);
  const validationSchema = useMemo(() => {
    return yup.object({
      departure_date: yup.date().required(),
      departure_time: yup.string().required(),
      departure_airport_code: yup.object({
        airport_code: yup.string().required(),
      }),
      arrival_airport_code: yup.object({
        airport_code: yup.string().required(),
      }),
      asked_customer_price: yup.number().positive().optional(),
      nbr_passengers: yup.number().positive().max(flight.capacity).optional(),
      check_availability_reason: yup.string().optional(),
      enable_send_sms: yup.boolean(),
      phone: yup.string().when('enable_send_sms', {
        is: true, // alternatively: (val) => val == true
        then: yup
          .string()
          .required('phone number is required for sms notifications'),
        otherwise: yup.string().nullable(),
      }),
    });
  }, [flight]);

  const onSubmit = useCallback(
    async (values, helpers: FormikHelpers<typeof initialValues>) => {
      // if (values.enable_send_sms && !isValidPhoneNumber(values.phone)) {
      //   helpers.setTouched({ phone: true }, true);
      //   return helpers.setFieldError('phone', 'invalid phone number');
      // }

      const data = {
        ...values,
        // necessary changes (departure_airport_code : stores the whole airport object)
        departure_airport_code: values.departure_airport_code.airport_code,
        arrival_airport_code: values.arrival_airport_code.airport_code,
        departure_date: formatDate(values.departure_date),
      };

      // delete unnecessary fields
      if (!values.enable_send_sms) {
        delete data.phone;
      }
      if (flight.price > 1) {
        delete data.asked_customer_price;
      }
      api.post(flight.action, data);
      setIsOpen(true);
    },
    [setIsOpen],
  );
  const initialTouched = { phone: true };
  return { initialValues, validationSchema, onSubmit, initialTouched };
};
