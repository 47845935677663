import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';
import { timeData, TIME_DATA } from '@/Utils/helpers';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import { ActionMeta, FocusEventHandler, ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';

type Props = InputProps;

const TimeInput: React.FC<Props> = props => {
  const [{ name, value, onBlur }, {}, { setValue, setTouched }] =
    useField<string>(props);

  const formattedValue = useMemo(() => {
    if (
      !props.text ||
      value.toUpperCase().includes(props.text?.toUpperCase())
    ) {
      return {
        label: value,
        value,
      };
    }
    let formatted = `${value.toUpperCase()}${
      props.text ? ' ' + props.text : ''
    }`;
    return {
      label: formatted,
      value: formatted,
    };
  }, [value, props.text]);

  const data = useMemo(() => {
    const arrayOfTimes = timeData(0, 24, 15, props.text);
    if (!value) return arrayOfTimes;
    // add the value to the array then sort it to keep the order
    // but only if the value don't already exists
    if (arrayOfTimes.find(t => t.value == value)) return arrayOfTimes;
    if (arrayOfTimes.find(t => t.value == formattedValue.value))
      return arrayOfTimes;

    const timeToAdd = formattedValue.value;
    // find the index to add the new time
    const idx = arrayOfTimes.findIndex((t, i, arr) => {
      const am_pm = t.value.includes('AM') === timeToAdd.includes('AM');
      return (
        am_pm &&
        arr[i].value > timeToAdd &&
        (!arr[i - 1] || arr[i - 1].value < timeToAdd)
      );
    });
    // add the value to the array then sort it to keep the order
    arrayOfTimes.splice(idx, 0, formattedValue);

    return arrayOfTimes;
  }, []);
  // const { formattedData, formattedValue } = useSelectData(
  //   data,
  //   value.toUpperCase(),
  //   false,
  //   props.text,
  // );

  const handleChange = (
    newValue: ValueType<typeof TIME_DATA[0], false>,
    actionMeta: ActionMeta<typeof TIME_DATA[0]>,
  ) => {
    if (actionMeta.action == 'clear') {
      setValue('');
    }
    setValue(
      (newValue as { value: string })?.value.toUpperCase() ?? undefined,
      true,
    );
  };

  const handleOptionCreate = (inputValue: string) => {
    const regexp = /^\d{2}\:\d{2} {1}am|pm$/;
    if (!regexp.test(inputValue.toLowerCase())) {
      return false;
    }
    const [hh, mm] = inputValue.split(':');
    const hours = parseInt(hh);
    const minutes = parseInt(mm);

    const isValidHour = (hour: number) =>
      Number.isInteger(hour) && hour > 0 && hour <= 12;
    const isValidMinutes = (minutes: number) =>
      (Number.isInteger(minutes) && hours > 0 && hours <= 12) ||
      Number.isNaN(minutes);

    return isValidHour(hours) && isValidMinutes(minutes);
  };

  const handleBlur: FocusEventHandler = e => {
    setTouched(true, true);
    props.onBlur && props.onBlur(e as any);
  };

  return (
    <FormElement {...props}>
      <CreatableSelect
        name={props.name}
        inputId={props.name}
        id={props.name}
        isClearable
        onChange={handleChange}
        onBlur={handleBlur}
        // onInputChange={handleInputChange}
        value={formattedValue}
        options={data}
        isValidNewOption={handleOptionCreate}
        hideSelectedOptions={false}
        noOptionsMessage={() => 'hh:mm am/pm'}
        formatCreateLabel={v => v}
        styles={{
          container: styles => ({ ...styles, width: '100%' }),
          control: styles => ({ ...styles, borderRadius: 0 }),
          valueContainer: styles => ({ ...styles, flexWrap: 'nowrap' }),
          menu: styles => ({ ...styles, zIndex: 10 }),
        }}
      />
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};

export default TimeInput;
