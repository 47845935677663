import { ErrorMessage } from '@/components/Forms';
import FormElement, { InputProps } from '@/components/Forms/FormElement';
import { useField } from 'formik';
import React from 'react';

type Props = InputProps;
const Off = () => (
  <svg
    style={{ padding: '10px', transform: 'scale(1.5)' }}
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M5.25 14.5C2.355 14.5 4.11761e-07 12.145 9.17939e-07 9.25C1.42412e-06 6.355 2.355 4 5.25 4C8.145 4 10.5 6.355 10.5 9.25C10.5 12.145 8.145 14.5 5.25 14.5ZM5.25 7C4.0095 7 3 8.0095 3 9.25C3 10.4905 4.0095 11.5 5.25 11.5C6.4905 11.5 7.5 10.4905 7.5 9.25C7.5 8.0095 6.4905 7 5.25 7Z'
        fill='#404040'
      />
      <path
        d='M15 12L11.2875 12C11.739 11.0947 12 10.0785 12 9C12 7.9215 11.739 6.90525 11.2875 6L15 6C16.6545 6 18 7.3455 18 9C18 10.6545 16.6545 12 15 12Z'
        fill='#404040'
      />
    </g>
  </svg>
);
const On = () => (
  <svg
    style={{ padding: '10px', transform: 'scale(1.5)' }}
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M12.9625 3.75C15.8575 3.75 18.2125 6.105 18.2125 9C18.2125 11.895 15.8575 14.25 12.9625 14.25C10.0675 14.25 7.71246 11.895 7.71246 9C7.71246 6.105 10.0675 3.75 12.9625 3.75ZM12.9625 11.25C14.203 11.25 15.2125 10.2405 15.2125 9C15.2125 7.7595 14.203 6.75 12.9625 6.75C11.722 6.75 10.7125 7.7595 10.7125 9C10.7125 10.2405 11.722 11.25 12.9625 11.25Z'
        fill='#29DF25'
      />
      <path
        d='M2.99996 6H6.71246C6.26096 6.90525 5.99996 7.9215 5.99996 9C5.99996 10.0785 6.26096 11.0948 6.71246 12H2.99996C1.34547 12 -3.43323e-05 10.6545 -3.43323e-05 9C-3.43323e-05 7.3455 1.34547 6 2.99996 6Z'
        fill='#29DF25'
      />
    </g>
  </svg>
);

const Toggle: React.FC<Props> = props => {
  const [{ value }, _, { setValue }] = useField(props.name);
  const click = () => setValue(!value);
  return (
    <FormElement {...props}>
      <span className='cursor-pointer flex items-center w-max' onClick={click}>
        {value ? <On /> : <Off />}
        {props.placeholder ?? props.label}
      </span>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default Toggle;
