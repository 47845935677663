import { EventForm } from '@/types/AddEventForms';
import { EventInput } from '@fullcalendar/react';

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const CalendarEventType = [
  'EmptyLeg',
  'Maintenance',
  'Transient',
] as const;
export type AvailabilityStatus = 'EmptyLeg' | 'Maintenance' | 'Transient';

export type EvoLuxStatus = `${'Skyshare' | 'Skyprivate'}-${
  | 'Pending'
  | 'Confirmed'}`;

export const eventColor = (type: AvailabilityStatus | EvoLuxStatus) => {
  if (type == 'EmptyLeg') {
    return 'dodgerblue';
  }

  if (type == 'Transient') {
    return 'teal';
  }

  if (type == 'Maintenance') {
    return 'tomato';
  }
  let color = '#cf72db';
  if (type.startsWith('Skyshare')) {
    color = '#4F46E5';
  }
  if (type.startsWith('Skyprivate')) {
    color = '#3ab956';
  }

  if (type.endsWith('Pending')) {
    color += '7a';
  }
  return color;
};

const FLIGHT = {
  departure_airport: { airport_code: 'KJFK', location: 'New York' },
  arrival_airport: { airport_code: 'KMIA', location: 'Miami' },
  price: 10000,
  flight_time: 4,
  date_range: {
    from: new Date(),
    to: new Date(),
  },
};
export const INITIAL_EVENTS: EventInput[] = [
  // {
  //   groupId: 'blueEvents', // recurrent events in this group move together
  //   daysOfWeek: ['2'],
  //   startTime: '10:45:00',
  //   endTime: '12:45:00',
  //   extendedProps: {
  //     type: 'Maintenance',
  //   } as EventForm,
  // },
  {
    id: createEventId(),
    title: 'Skyprivate-Confirmed',
    start: todayStr.substr(0, 8) + '10',
    end: todayStr.substr(0, 8) + '11',
    color: eventColor('Skyprivate-Confirmed'),
    extendedProps: {
      type: 'Skyprivate-Confirmed',
      ...FLIGHT,
    },
  },
  {
    id: createEventId(),
    title: 'EmptyLeg',
    start: todayStr.substr(0, 8) + '12',
    end: todayStr.substr(0, 8) + '14',
    color: eventColor('EmptyLeg'),
    extendedProps: {
      type: 'EmptyLeg',
      ...FLIGHT,
    } as EventForm,
  },
  {
    id: createEventId(),
    title: 'Maintenance',
    start: new Date(),
    end: new Date().setHours(20),
    color: eventColor('Maintenance'),
    allDay: false,
    extendedProps: {
      type: 'Maintenance',
    } as EventForm,
  },
  {
    id: createEventId(),
    title: 'Skyprivate-Pending',
    start: todayStr.substr(0, 8) + '15',
    end: todayStr.substr(0, 8) + '16',
    color: eventColor('Skyprivate-Pending'),
    extendedProps: { type: 'Skyprivate-Pending', ...FLIGHT },
  },
  {
    id: createEventId(),
    title: 'Skyshare-Pending',
    start: todayStr.substr(0, 8) + '20',
    end: todayStr.substr(0, 8) + '21',
    color: eventColor('Skyshare-Pending'),
    extendedProps: { type: 'Skyshare-Pending', ...FLIGHT },
  },
  // {
  // 	id: createEventId(),
  // 	title: 'Timed event',
  // 	start: todayStr + 'T12:00:00',
  // },
];

export function createEventId() {
  return String(eventGuid++);
}
