import Accordion from '@/components/Accordion';
import { AirportInput, Input, Toggle } from '@/components/Forms';
import { SelectDataEntry } from '@/components/Forms/Select';
import SavedRouteCard from '@/components/SavedRouteCard';
import { useSavedRoutesForm } from '@/Utils/formHooks/useSavedRoutesForm';
import { FieldArray, useField } from 'formik';
import React, { useMemo } from 'react';

export type SavedRoutesProps = { isSignup?: boolean };

const SavedRoutes: React.FC<SavedRoutesProps> = ({ isSignup }) => {
  const { addRoute, deleteRoute, saved_routes } = useSavedRoutesForm({
    isSignup,
  });
  const [{ value }] = useField('saved_routes');

  useMemo(() => {
    saved_routes ?? value ?? [];
  }, [value, saved_routes]);

  return (
    <FieldArray name='saved_routes'>
      {({ push, remove }) => (
        <>
          <AirportInput
            className='w-full md:w-1/2'
            name='savedRoutesForm.departure_airport'
            label='Departure location'
          />
          <AirportInput
            className='w-full md:w-1/2'
            name='savedRoutesForm.arrival_airport'
            label='Arrival location'
          />
          <Input
            name='savedRoutesForm.radius'
            label='Show flights within radius (1-300)'
            text='miles'
            type='number'
            min='1'
            max='300'
            className='w-full'
          />
          <Toggle
            name='savedRoutesForm.returned'
            label='return trip'
            placeholder='Automatically add return trip'
            className='w-full'
          />
          <dl className='mb-8'>
            <button
              className='btn'
              type='button'
              onClick={() => addRoute(push)}
            >
              Add route
            </button>
          </dl>
          <Accordion
            openByDefault
            title={'Saved Routes:' + saved_routes?.length}
          >
            {!saved_routes?.length ? (
              <p className='block w-full leading-relaxed font-semibold px-8'>
                no saved routes !
              </p>
            ) : (
              saved_routes?.map((route, i) => (
                <SavedRouteCard
                  key={route.id}
                  {...route}
                  onRemove={() => {
                    try {
                      deleteRoute(route.id!);
                      remove(i);
                    } catch (e) {
                      console.log('error deleting route');
                    }
                  }}
                />
              ))
            )}
          </Accordion>
        </>
      )}
    </FieldArray>
  );
};
export default React.memo(SavedRoutes);
