import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

type Props = {
  title: string;
  openByDefault?: boolean;
  className?: { container: string; title: string };
};

const Accordion: React.FC<Props> = ({
  title,
  openByDefault = false,
  className,
  children,
}) => {
  const [open, setOpen] = useState(openByDefault);

  return (
    <div
      className={`bg-white p-4 border border-gray-100 w-full ${
        className?.container ?? ''
      }`}
    >
      <h1
        onClick={() => setOpen(current => !current)}
        className={`w-full flex justify-between items-center 
				cursor-pointer p-2 text-3xl box-border border-0 border-b border-solid border-gray-200 ${className?.title}`}
      >
        <span>{title}</span>
        {open ? <FiMinimize2 /> : <FiMaximize2 />}
      </h1>
      {open && children}
    </div>
  );
};
export default Accordion;
