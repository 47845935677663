import { User } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import EditProfileForm from '@/components/EditProfileForm';
import { ProfileInfoProps } from '@/components/Profile/ProfileInfo';
import Tabs, { Tab } from '@/components/Tabs';
import React, { useState } from 'react';

export type EditProfileProps = ProfileInfoProps & {
  user: User;
  user_route_preferences: any;
  action: string;
  newsletter_frequencies: {
    daily: number;
    weekly: number;
    monthly: number;
  }; // {"daily": 0, "weekly":1, "monthly":2}
};

const EditProfile: React.FC<EditProfileProps> = props => {
  return (
    <ReactQueryProvider>
      <EditProfileForm {...props} />
    </ReactQueryProvider>
  );
};
export default EditProfile;
