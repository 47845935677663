import React, { AnchorHTMLAttributes } from 'react';

type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

const StripeButton: React.FC<Props> = ({ children, ...other }) => {
  return (
    <a
      className='cursor-pointer my-5 px-10 py-5 rounded-md border-none text-xl font-black bg-gray-200 hover:bg-gray-400 text-gray-900'
      target='_blank'
      {...other}
    >
      {children}
    </a>
  );
};
export default StripeButton;
