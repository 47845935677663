import ReactQueryProvider from '@/API/ReactQueryProvider';
import RegisterForm from '@/components/Profile/RegisterForm';
import { EditProfileProps } from '@/pages/EditProfile';
import React from 'react';

export type RegisterProps = EditProfileProps & {
  recaptchaKey: string;
};

const Register: React.FC<RegisterProps> = props => {
  return (
    <ReactQueryProvider>
      <RegisterForm {...props} />
    </ReactQueryProvider>
  );
};
export default Register;
