import ErrorMessage from '@/components/Forms/ErrorMessage';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import ReactSelect, { OptionsType } from 'react-select';
import FormElement, { InputProps } from './FormElement';

export type SelectDataEntry = { label: string; value: string };
type Props = InputProps & {
  data: SelectDataEntry[] | string[] | readonly string[];
  isMulti?: boolean;
  isNotClearable?: boolean; // clearable by default
};

const Select: React.FC<Props> = ({
  data,
  isMulti,
  isNotClearable = false,
  ...props
}) => {
  const [{ name, value, onBlur }, {}, { setValue }] = useField<
    undefined | string | string[]
  >(props);

  const { formattedData, formattedValue } = useSelectData(data, value, isMulti);

  return (
    <FormElement {...props}>
      <ReactSelect
        name={props.name}
        inputId={props.name}
        onBlur={onBlur}
        // blurInputOnSelect
        isClearable={!isNotClearable}
        isMulti={isMulti}
        placeholder={props.placeholder}
        styles={{
          container: styles => ({ ...styles, width: '100%' }),
          control: styles => ({ ...styles, borderRadius: 0 }),
          valueContainer: styles => ({ ...styles, flexWrap: 'nowrap' }),
          menu: styles => ({ ...styles, zIndex: 10 }),
        }}
        options={formattedData}
        isDisabled={props.disabled}
        value={formattedValue}
        // hideSelectedOptions
        onChange={(current, { action }) => {
          if (action == 'clear') {
            setValue('');
          }
          if (isMulti) {
            setValue(
              (current as OptionsType<{ value: string }>).map(op => op.value),
            );
            return;
          }
          setValue((current as { value: string })?.value ?? undefined, true);
        }}
      />
      <ErrorMessage name={name} />
    </FormElement>
  );
};
export default Select;

export const useSelectData = (
  data: string[] | readonly string[] | SelectDataEntry[],
  value?: string | string[],
  isMulti?: boolean,
  text?: string,
) => {
  const formattedData = useMemo(() => {
    if (!data?.length) return [] as SelectDataEntry[];

    if (typeof data[0] == 'object') {
      return data as SelectDataEntry[];
    }
    return data.map(d => ({ label: `${d} ${text ?? ''}`, value: d }));
  }, [data]);

  const formattedValue = useMemo(() => {
    if (value === null || value === undefined || value === '') return null;
    if (isMulti && typeof value == 'object') {
      // return value.length ? value.map(v => ({ label: v, value: v })) : null;
      return value.length
        ? value.map(
            v =>
              formattedData.find(d => d.value == v) ?? { label: v, value: v },
          )
        : null;
    }
    return (
      formattedData.find(d => d.value == value || d.label == value) ?? {
        label: `${value} ${text ?? ''}`,
        value,
      }
    );
  }, [value, formattedData]);
  return { formattedData, formattedValue };
};

export const RawSelect: React.FC<
  Props & { setValue: (s: string | string[]) => void }
> = ({ data, isMulti, isNotClearable = false, value, setValue, ...props }) => {
  const { formattedData, formattedValue } = useSelectData(
    data,
    value as any,
    isMulti,
  );

  return (
    <FormElement {...props}>
      <ReactSelect
        name={props.name}
        inputId={props.name}
        onBlur={props.onBlur}
        // blurInputOnSelect
        isClearable={!isNotClearable}
        isMulti={isMulti}
        placeholder={props.placeholder}
        styles={{
          container: styles => ({ ...styles, width: '100%' }),
          control: styles => ({ ...styles, borderRadius: 0 }),
          valueContainer: styles => ({ ...styles, flexWrap: 'nowrap' }),
          menu: styles => ({ ...styles, zIndex: 10 }),
        }}
        options={formattedData}
        isDisabled={props.disabled}
        value={formattedValue}
        // hideSelectedOptions
        onChange={(current, { action }) => {
          if (action == 'clear') {
            setValue('');
          }
          if (isMulti) {
            setValue(
              (current as OptionsType<{ value: string }>).map(op => op.value),
            );
            return;
          }
          setValue((current as { value: string })?.value ?? undefined);
        }}
      />
    </FormElement>
  );
};
