import ErrorsTooltip from '@/components/ErrorsTooltip';
import { SubmitBtn } from '@/components/Forms';
import { SelectDataEntry } from '@/components/Forms/Select';
import ProfileInfo from '@/components/Profile/ProfileInfo';
import ProfileSettings from '@/components/Profile/ProfileSettings';
import ProfilePicture from '@/components/ProfilePicture';
import Tabs from '@/components/Tabs';
import { EditProfileProps } from '@/pages/EditProfile';
import { useEditProfileForm } from '@/Utils/formHooks/useEditProfileForm';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';

const EditProfileForm: React.FC<EditProfileProps> = ({
  user,
  user_route_preferences,
  newsletter_frequencies,
  google_maps_api_key,
  timeZones,
  action,
}) => {
  const { errors, ...formData } = useEditProfileForm(
    user,
    user_route_preferences,
    action,
  );
  const frequencies = useMemo(() => {
    // transforming object to an array
    let array: SelectDataEntry[] = [];
    for (const key in newsletter_frequencies) {
      if (Object.prototype.hasOwnProperty.call(newsletter_frequencies, key)) {
        const element = newsletter_frequencies[key];

        array = [...array, { label: key, value: element }];
      }
    }
    return array;
  }, [newsletter_frequencies]);

  const tabs = [
    {
      title: 'Profile Settings',
      Content: () => <ProfileInfo {...{ google_maps_api_key, timeZones }} />,
    },
    {
      title: 'Notification Settings',
      Content: () => <ProfileSettings {...{ frequencies }} />,
    },
    // {
    //   title: 'Subscription Settings',
    //   Content: () => <ProfileSubscription tier={0} />,
    // },
  ];
  return (
    <Formik {...formData}>
      {({ errors: formErrors, submitCount }) => (
        <Form className='w-11/12 justify-center lg:w-4/5 flex flex-wrap mx-auto'>
          <ProfilePicture url={user.logo.url} tier={0} />
          <ErrorsTooltip errors={errors} />
          <Tabs
            tabs={tabs}
            current={
              submitCount > 0 && Object.keys(formErrors).length ? 0 : undefined
            }
          />
          {submitCount > 0 && Object.keys(formErrors).length && (
            <p className='text-red-500 p-2 w-full font-bold'>
              cannot submit, the form contains errors
            </p>
          )}
          <div className=' py-10 w-full justify-center flex flex-wrap '>
            <SubmitBtn text='Save Account Details' />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default EditProfileForm;
