import Popover from '@/components/Popover';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FiInfo } from 'react-icons/fi';

export type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  name: string;
  label?: string;
  // type?: 'text' | 'password' | 'checkbox';
  text?: string;
  preText?: string;
  noLabel?: boolean;
  info?: string;
};

const FormElement: React.FC<InputProps> = props => {
  const { className, noLabel, type, info } = props;
  return (
    <dl
      className={className}
      style={{ display: type == 'checkbox' ? 'flex' : undefined }}
    >
      {!noLabel && (
        <dt className='justify-start'>
          <label style={{ fontWeight: 'bold' }} htmlFor={props.name}>
            {props.label || props.name.replace('_', ' ')}
          </label>
          {info && (
            <Popover
              largerText
              Btn={() => <FiInfo size={20} className='mx-4 text-gray-400' />}
              content={info}
            />
          )}
        </dt>
      )}
      <dd style={props.style}>{props.children}</dd>
    </dl>
  );
};
export default FormElement;
