import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';
import { useField } from 'formik';
import React from 'react';

type Props = InputProps;

const Input: React.FC<Props> = ({ preText, ...props }) => {
  const [{ value, ...formik }, {}] = useField(props);

  return (
    <FormElement {...props}>
      <div className='flex items-baseline'>
        {preText && (
          <p className='pr-1 inline-block text-gray-700 font-bold'>{preText}</p>
        )}
        <input
          {...props}
          className='placeholder-gray-400'
          placeholder={props.placeholder || '....'}
          type={props.type || 'text'}
          id={props.name}
          {...formik}
          value={value ?? props.value ?? ''}
        />
        {props.text && (
          <p className='pl-2 inline-block text-gray-500 font-bold'>
            {props.text}
          </p>
        )}
        {props.children}
      </div>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default Input;
