import ReactQueryProvider from '@/API/ReactQueryProvider';
import EmptyLegsResults from '@/components/EmptyLegsResults';
import FloatingBtn from '@/components/FloatingBtn';
import SearchForm from '@/components/SearchForm';
import SearchMap from '@/components/SearchMap';
import '@/styles/search';
import useSearchEmptyLegForm, {
  useUpdateSearchEmptyLeg,
} from '@/Utils/formHooks/useSearchEmptyLegForm';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

type Props = { google_maps_api_key: string };
const Content: React.FC<Props> = ({ google_maps_api_key }) => {
  const [selected, setSelected] = useState<number>();
  const updateSelected = (i: number) => {
    if (i !== selected) {
      return setSelected(i);
    }
    //TODO: a temporary fix for lazy loading issues
    setSelected(undefined);
    setTimeout(() => {
      setSelected(i);
    }, 0);
  };

  const {
    data,
    isLoading,
    isError,
    initialValues,
    onSubmit,
    validate,
    setSearchParams,
    enabled,
  } = useSearchEmptyLegForm();

  useEffect(() => {
    setSelected(undefined);
  }, [data.emptylegs]);

  return (
    <>
      <FloatingBtn />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {formikHelpers => {
          // const { isLoading, data, isError, error } =
          //   useSearchForm(formikHelpers);
          useUpdateSearchEmptyLeg(
            setSearchParams,
            formikHelpers.values,
            enabled,
          );
          const { from, to } = formikHelpers.values;
          return (
            <div id='search-emptylegs-page'>
              <div className='search-form order-2 lg:order-1'>
                <h1 className='text-5xl px-5 md:px-10 py-10'>
                  Search EmptyLegs
                </h1>
                <>
                  <SearchForm amenities={data.amenitiesList} />
                  {!from?.airport_code && !to?.airport_code ? (
                    <p className='p-10'>
                      Please select a departure or an arrival ...
                    </p>
                  ) : !isLoading && isError ? (
                    <p className='p-10 bg-red-200'>
                      An error occurred ! Please Try Again
                    </p>
                  ) : isLoading || data.emptylegs ? (
                    <EmptyLegsResults
                      data={data.emptylegs}
                      isLoading={isLoading}
                      selected={selected}
                      showOnMap={updateSelected}
                    />
                  ) : (
                    <p className='p-10'> Ready to Search</p>
                  )}
                </>
              </div>

              <SearchMap
                apiKey={google_maps_api_key}
                routes={data?.emptylegs}
                selectedRoute={selected}
                goTo={updateSelected}
              />
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const EmptyLegSearch: React.FC<Props> = ({ google_maps_api_key }) => {
  return (
    <ReactQueryProvider>
      <Content google_maps_api_key={google_maps_api_key} />
    </ReactQueryProvider>
  );
};
export default EmptyLegSearch;
