import React from 'react';
import { Flight } from '@/API/models';
import ReactQueryProvider from '@/API/ReactQueryProvider';
import ChangeFlight, {
  ChangeFlightProps,
} from '@/components/Operator/ChangeFlight';
import DisplayFlight, {
  DisplayFlightProps,
} from '@/components/Operator/DisplayFlight';

type Props = {
  displayOnly?: boolean;
  change: Flight & { reason: string };
} & DisplayFlightProps &
  ChangeFlightProps;

const ChangeRequest: React.FC<Props> = ({
  displayOnly = false,
  change,
  ...props
}) => {
  return (
    <div>
      <ReactQueryProvider>
        {displayOnly ? (
          <DisplayFlight {...change} {...props} />
        ) : (
          <ChangeFlight {...change} {...props} />
        )}
      </ReactQueryProvider>
    </div>
  );
};
export default ChangeRequest;
