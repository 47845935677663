import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { EventForm } from '@/types/AddEventForms';
import { formatDate } from '@fullcalendar/react';

export const useInitialTimeRange = (name = 'date_range') => {
  const { values, setFieldValue } = useFormikContext<EventForm>();
  useEffect(() => {
    setFieldValue(
      `${name}.to_time`,
      formatDate(values[name].to, { hour: '2-digit', minute: '2-digit' }),
    );
    setFieldValue(
      `${name}.from_time`,
      formatDate(values[name].from, {
        hour: '2-digit',
        minute: '2-digit',
      }),
    );
  }, []);
};
