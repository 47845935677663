import SkeletonBlock from '@/components/Skeleton/SkeletonBlock';
import React from 'react';

type Props = {};

const CardSkeleton: React.FC<Props> = ({}) => {
  return (
    <div className={`my-card overflow-hidden shadow-md rounded-md`}>
      <SkeletonBlock height={250} />
      <div className='mt-4 flex justify-between p-5'>
        <div className='max-w-min'>
          <SkeletonBlock width={50} height={20} />
          <SkeletonBlock width={150} height={50} className='my-2' />
          <SkeletonBlock width={200} height={30} className='my-2' />
        </div>
        <div className='w-1/2 text-right text-2xl flex flex-col items-end'>
          <SkeletonBlock width={100} height={20} className='my-2' />
          <SkeletonBlock width={120} height={20} className='my-2' />
        </div>
      </div>
    </div>
  );
};
export default CardSkeleton;
