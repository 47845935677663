import { Emptyleg } from '@/API/models';
import { createDateObj, formatMoney } from '@/Utils/helpers';
import React, { useMemo } from 'react';
import {
  FiCalendar,
  FiChevronRight,
  FiClock,
  FiEdit,
  FiEyeOff,
  FiTrash,
} from 'react-icons/fi';

const RowSection: React.FC<{ small?: boolean }> = ({
  children,
  small = false,
}) => {
  return (
    <div
      className={`mt-2 ${
        small ? 'md:w-52' : 'md:w-96'
      }  w-full  border-0 border-solid border-l-2 border-gray-500  px-4`}
    >
      {children}
    </div>
  );
};

type Props = {
  emptyleg: Emptyleg;
  onEdit: (el: Emptyleg) => void;
  onDelete: () => void;
  onHide: () => void;
};

const EmptyLegRow: React.FC<Props> = ({
  emptyleg,
  onEdit,
  onDelete,
  onHide,
}) => {
  const dateRange = useMemo(() => {
    return {
      earliest: createDateObj(emptyleg.date1),
      latest: createDateObj(emptyleg.date2),
    };
  }, []);

  return (
    <button
      className={`flex-grow min-w-full rounded-md border-4 border-solid ${
        emptyleg.evolux ? 'border-indigo-300' : ''
      } ${
        emptyleg.hidden ? 'opacity-50' : ''
      } shadow-md  text-left cursor-pointer px-8 py-6 bg-gray-100 flex flex-row items-center justify-between flex-wrap lg:flex-nowrap`}
    >
      <div className='max-w-min px-5' style={{ minWidth: '20%' }}>
        <h2 className='text-gray-900 title-font text-6xl font-medium flex'>
          <div>
            {emptyleg.departureAirport.airport_code ?? 'N/A'}
            <p className='text-gray-500 text-xl'>
              {emptyleg.departureAirport.location ?? 'N/A'}
            </p>
          </div>
          <FiChevronRight
            className='self-center mx-2 flex-shrink-0'
            size={20}
          />
          <div>
            {emptyleg.arrivalAirport.airport_code ?? 'N/A'}
            <p className='text-gray-500 text-xl'>
              {emptyleg.arrivalAirport.location ?? 'N/A'}
            </p>
          </div>
        </h2>
      </div>
      <RowSection>
        <h4 className='text mt-4'>Available to depart this location:</h4>
        <h4 className='text-2xl flex justify-between'>
          {dateRange.earliest.date}
          <FiCalendar />
          {dateRange.latest.date}
        </h4>
        <h4 className='text-2xl flex justify-between'>
          {/* remove the last letter to fix timezone */}
          {`${dateRange.earliest.time} ${emptyleg.time_zone}`}
          <FiClock />
          {`${dateRange.latest.time} ${emptyleg.time_zone}`}
        </h4>
      </RowSection>
      <RowSection small>
        <h4 className='text mt-4'>Flight Time</h4>
        <h4 className='text-2xl'>{emptyleg.flightTimeDec ?? '??'} hours</h4>
      </RowSection>
      <RowSection small>
        <h4 className='text mt-4'>Price</h4>
        <h4 className='text-2xl'>
          {emptyleg.price > 1 ? formatMoney(emptyleg.price) : 'TBD'}
        </h4>
      </RowSection>
      <RowSection small>
        {emptyleg.evolux ? (
          <FiTrash
            onClick={onDelete}
            size={20}
            className='p-4 mx-1 hover:bg-gray-200   bg-white rounded-md cursor-pointer opacity-80 hover:opacity-100'
          />
        ) : emptyleg.hidden ? (
          <FiEyeOff
            onClick={onHide}
            size={20}
            className='p-4 mx-1 bg-black text-white hover:text-black   hover:bg-white rounded-md cursor-pointer opacity-80 hover:opacity-100'
          />
        ) : (
          <FiEyeOff
            onClick={onHide}
            size={20}
            className='p-4 mx-1 hover:bg-gray-200   bg-white rounded-md cursor-pointer opacity-80 hover:opacity-100'
          />
        )}
        {!emptyleg.hidden && (
          <FiEdit
            size={20}
            onClick={() => onEdit(emptyleg)}
            className='p-4 mx-1 hover:bg-gray-200 bg-white rounded-md cursor-pointer opacity-80 hover:opacity-100 '
          />
        )}
      </RowSection>
    </button>
  );
};
export default EmptyLegRow;
