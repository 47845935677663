import { ErrorMessage as EM } from 'formik';
import React from 'react';

type Props = {
  name: string;
};

const ErrorMessage: React.FC<Props> = ({ name }) => {
  return (
    <EM name={name}>{error => <p className='text-red-500 p-2'>{error}</p>}</EM>
  );
};
export default React.memo(ErrorMessage);
