import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { DebugForm, Select, SubmitBtn } from '@/components/Forms';
import Modal from '@/components/Modal';
import EmptyLegFields from '@/components/Operator/Shared/EmptyLegFields';
import MaintenanceFields from '@/components/Operator/Shared/MaintenanceFields';
import TransientFields from '@/components/Operator/Shared/TransientFields';
import { CalendarEventType } from '@/Utils/event-utils';
import { useAddEventForm } from '@/Utils/formHooks/useAddEventForm';
import useMediaQuery from '@/Utils/useMediaQuery';
import { DateSelectArg } from '@fullcalendar/react';

interface Props {
  info?: DateSelectArg;
  modalProps: ReactModal.Props & { onRequestClose: () => void };
}

const AddEventModal: FC<Props> = ({ modalProps, info }) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const form = useAddEventForm(modalProps.onRequestClose, info);

  return (
    <Modal
      style={{
        content: {
          overflow: 'visible',
          maxWidth: !isMobile ? '50%' : undefined,
        },
      }}
      {...modalProps}
    >
      <Formik {...form} enableReinitialize>
        {({ values }) => (
          <Form className='mx-auto flex flex-wrap p-4'>
            <Select
              name='type'
              label='Choose an event type'
              data={CalendarEventType}
              isNotClearable
            />
            {values.type === 'EmptyLeg' ? (
              <EmptyLegFields />
            ) : values.type === 'Maintenance' ? (
              <MaintenanceFields />
            ) : values.type === 'Transient' ? (
              <TransientFields />
            ) : null}
            <SubmitBtn text='Add Event' />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddEventModal;
