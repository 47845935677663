import { User } from '@/API/models';
import { Route, useUpdateAccount } from '@/API/userApi';
import { formatDate, strDate } from '@/Utils/helpers';
import { yup } from '@/Utils/validationConfig';
import { useMemo, useState } from 'react';

export const useEditProfileForm = (
  user: User,
  routes: Route[],
  action: string,
) => {
  const [errors, setErrors] = useState<string[]>();

  const validationSchema = useMemo(
    () =>
      yup.object({
        date_of_birth: yup
          .string()
          .required()
          .test({
            message: 'the format must be mm/dd/yyyy',
            test: value => {
              if (!value) return false;
              return formatDate(strDate(value)) == value;
            },
          }),
        email: yup.string().email().required(),
        username: yup.string().min(2, 'too short').required(),
        first_name: yup.string().min(2, 'too short').required(),
        last_name: yup.string().min(2, 'too short').required(),
        password: yup.string().min(8).nullable(),
        password_confirmation: yup.string().when('password', {
          is: (val: string) => val?.length > 0,
          then: yup
            .string()
            .required()
            .oneOf([yup.ref('password')], 'Passwords must match'),
          otherwise: yup.string().nullable(),
        }),
      }),
    [],
  );
  const initialValues = useMemo(() => {
    const {
      email,
      username,
      first_name,
      last_name,
      street,
      city,
      country,
      state,
      postal_code,
      time_zone,
      height,
      weight,
      phone,
      receive_shared_flight_notification,
      receive_emptyleg_flight_notification,
      newsletter_frequency,
      date_of_birth,
    } = user;

    return {
      savedRoutesForm: {
        departure_airport: {},
        arrival_airport: {},
        returned: false,
        radius: 300,
      } as Route,
      // user Data
      receive_shared_flight_notification,
      receive_emptyleg_flight_notification,
      newsletter_frequency,
      saved_routes: routes,
      password: '',
      password_confirmation: '',
      email,
      username,
      first_name,
      last_name,
      street,
      city,
      country,
      state,
      postal_code,
      time_zone,
      height,
      weight,
      phone,
      date_of_birth,
    };
  }, [user]);
  // initially touched to fix validation check
  const initialTouched = {
    savedRoutesForm: {
      arrival_airport: { airport_code: true },
      departure_airport: { airport_code: true },
      radius: true,
    },
  };

  const updateAccount = useUpdateAccount();

  const onSubmit = async values => {
    const data = { user: values };
    delete data.user.savedRoutesForm;
    delete data.user.saved_routes;
    try {
      const response = await updateAccount.mutateAsync(data);
      if (response.success) {
        return window.location.assign(response.signin_redirection);
      } else {
        setErrors(response.errors ?? response.error);
      }
    } catch (error) {
      setErrors([(error as Error).message]);
    }
    // show success modal
  };
  return { initialValues, validationSchema, initialTouched, onSubmit, errors };
};
