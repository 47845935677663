import { Airport } from '@/API/airportApi';
import { TimeInput } from '@/components/Forms';
import { AirportInputContent } from '@/components/Forms/AirportInput';
import { InputProps } from '@/components/Forms/FormElement';
import { useField } from 'formik';
import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';

// use the text prop to override the displayed value (to format it for ex)
type Props = InputProps & { text?: string; component?: React.FC };

const EditText: React.FC<Props> = ({
  text,
  component: Component,
  ...props
}) => {
  const [formik, ,] = useField(props);
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className='relative'>
      {!isEditing ? (
        <button
          type='button'
          className='bg-none appearance-none border-none p-0 m-0 relative contents'
          onClick={() => setIsEditing(true)}
        >
          {Component ? <Component /> : text ?? formik.value}
          {!Component && <FiEdit className='ml-4' />}
        </button>
      ) : (
        <input
          autoFocus
          placeholder={props.placeholder || '....'}
          type={props.type || 'text'}
          id={props.name}
          disabled={props.disabled}
          {...formik}
          onBlur={e => {
            formik.onBlur(e);
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
};
export const EditAirport: React.FC<Props> = ({ text, ...props }) => {
  const [formik, ,] = useField<Airport>(props);
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className='relative'>
      {!isEditing ? (
        <button
          type='button'
          className='bg-none appearance-none border-none p-0 m-0 relative contents'
          onClick={() => setIsEditing(true)}
        >
          {text ?? formik.value.airport_code}
          <FiEdit className='ml-4' />
        </button>
      ) : (
        <AirportInputContent
          name={props.name}
          onBlur={e => {
            formik.onBlur(e);
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
};

export const EditTime: React.FC<Props> = props => {
  const [isEditing, setIsEditing] = useState(false);
  const [{ value, onBlur }, ,] = useField(props);

  return isEditing ? (
    <TimeInput
      className='ml-10 mr-auto w-full'
      {...props}
      onBlur={e => {
        onBlur(e);
        setIsEditing(false);
      }}
    />
  ) : (
    <p
      className='ml-10 mr-auto cursor-pointer'
      onClick={() => setIsEditing(true)}
    >
      {value ?? 'no value'}
      <FiEdit className='mx-4' />
    </p>
  );
};
export default EditText;
