import { useFormikContext } from 'formik';
import React from 'react';

interface Props {
  text: string;
  className?: string;
}

const SubmitBtn: React.FC<Props> = ({ text = 'submit', className }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <dl className={className}>
      <dd>
        <input
          type='submit'
          className='btn btn-red'
          value={isSubmitting ? 'please wait ....' : text}
          disabled={isSubmitting}
        />
      </dd>
    </dl>
  );
};

export default SubmitBtn;
