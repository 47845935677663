import { useUpdateAccountDetails } from '@/API/userApi';
import Spinner from '@/components/Spinner';
import { SUBSCRIPTIONS } from '@/Utils/constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { IoPersonCircleOutline } from 'react-icons/io5';

type Props = {
  url?: string;
  tier?: number;
};

const ProfilePicture: React.FC<Props> = ({ url, tier }) => {
  const [photo, setPhoto] = useState<File>();
  const valueURL = useMemo(
    () =>
      photo
        ? URL.createObjectURL(photo)
        : url == '/defaults/user-logo.png'
        ? ''
        : url,
    [photo, url],
  );
  const fileInput = useRef<HTMLInputElement>(null);

  const {
    mutate: updateDetails,
    isError,
    error,
    isLoading,
  } = useUpdateAccountDetails();
  useEffect(() => {
    if (!photo) return;
    // upload file
    updateDetails({ logo: photo });
  }, [photo]);

  return (
    <div className='w-full flex justify-center flex-col items-center'>
      <div
        className={
          'bg-gradient-to-r p-2 rounded-full overflow-hidden ' +
          (tier != undefined ? SUBSCRIPTIONS[tier].bg : '')
        }
      >
        <div
          onClick={() => {
            fileInput.current?.click();
          }}
          className='relative w-64 h-64 m-4 cursor-pointer rounded-full bg-cover bg-center overflow-hidden'
          style={{
            backgroundImage: `url(${valueURL})`,
          }}
        >
          <div
            className={`absolute top-0 left-0 right-0 bottom-0 
				transition-all bg-gray-700 hover:opacity-80 opacity-0
				flex justify-center items-center text-gray-300 bg-gradient-to-r ${
          tier != undefined ? SUBSCRIPTIONS[tier].bg : ''
        } ${isLoading ? 'opacity-90' : ''}`}
          >
            {isLoading ? <Spinner /> : <FiUpload size={40} />}
          </div>
          {(!url || url == '/defaults/user-logo.png') && !photo ? (
            <div
              className={`h-full w-full flex justify-center items-center bg-gray-300 text-gray-400`}
            >
              <IoPersonCircleOutline size={80} />
            </div>
          ) : null}
          <input
            ref={fileInput}
            name='image'
            type='file'
            style={{ display: 'none' }}
            onChange={e => {
              const input = e.currentTarget;
              if (!input?.files?.length) {
                return;
              }
              const imageFile = input.files[0];
              setPhoto(imageFile);
            }}
          />
        </div>
      </div>
      {isError && <p className='text-red-400'>{(error as any).message}</p>}
    </div>
  );
};
export default ProfilePicture;
