import api from '@/API/config';
import { Emptyleg } from '@/API/models';
import { QueryMethod } from '@/types/API';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type EmptylegsResponse = {
  emptylegs: Emptyleg[];
  success: boolean;
  errors?: any[];
};
export const useGetAcEmptylegs: QueryMethod<Emptyleg[], string> = (
  ac_id,
  options,
) => {
  return useQuery(
    ['ac_emptylegs', { ac_id }],
    async () => {
      const { data } = await api.get<EmptylegsResponse>(
        `/operator/aircrafts/${ac_id}/emptylegs`,
      );
      if (data.errors?.length) {
        throw Error();
      }
      return data.emptylegs.sort((a, b) => {
        const dateA = new Date(a.date1);
        const dateB = new Date(b.date1);
        return dateA < dateB ? -1 : 1;
      });
    },
    { ...options },
  );
};

type CreateEmptylegRequest = {
  emptyLeg: any;
  aircraft_id: string;
};
type CreateEmptylegResponse = {
  redirect_url: string;
  success: boolean;
};

export const useAddEmptyleg = (options?) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ emptyLeg, aircraft_id }: CreateEmptylegRequest) => {
      const { data } = await api.post<CreateEmptylegResponse>(
        `/operator/aircrafts/${aircraft_id}/emptylegs`,
        { emptyLeg },
      );
      return data;
    },
    {
      onMutate: req => {
        // save previous data
        const previous = queryClient.getQueryData([
          'ac_emptylegs',
          { ac_id: `${req.aircraft_id}` },
        ]);
        queryClient.invalidateQueries([
          'ac_emptylegs',
          { ac_id: `${req.aircraft_id}` },
        ]);
        // console.log({ previous });

        // queryClient.setQueryData(
        //   ['ac_emptylegs', { ac_id: `${req.aircraft_id}` }],
        //   (prev: Emptyleg[]) =>
        //     prev ? [...prev, req.emptyLeg] : [req.emptyLeg],
        // );

        // Return a context object with the snapshotted value
        return { previous };
      },
      onError: (err, req, ctx: { previous: Emptyleg[] }) => {
        queryClient.setQueryData(
          ['ac_emptylegs', { ac_id: `${req.aircraft_id}` }],
          ctx.previous,
        );
      },
      ...options,
    },
  );
};

type UpdateEmptylegRequest = CreateEmptylegRequest & { id: string };
export const useEditEmptyleg = (options?) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ emptyLeg, aircraft_id, id }: UpdateEmptylegRequest) => {
      const { data } = await api.put<CreateEmptylegResponse>(
        `/operator/aircrafts/${aircraft_id}/emptylegs/${id}`,
        { emptyLeg },
      );
      return data;
    },
    {
      onMutate: req => {
        queryClient.invalidateQueries([
          'ac_emptylegs',
          { ac_id: `${req.aircraft_id}` },
        ]);
        // queryClient.setQueryData(
        //   ['ac_emptylegs', { ac_id: `${req.aircraft_id}` }],
        //   (previous: Emptyleg[]) => {
        //     if (!previous) {
        //       return;
        //     }
        //     console.log({ req });
        //     return previous.map(leg => {
        //       if (leg.emptyleg_id == req.id) {
        //         return { ...leg, ...req.emptyLeg };
        //       }
        //       return leg;
        //     });
        //   },
        // );

        // queryClient.invalidateQueries([
        //   'ac_emptylegs',
        //   { ac_id: req.aircraft_id },
        // ]);
      },
      ...options,
    },
  );
};

type DeleteEmptylegRequest = { emptyleg: string; aircraft_id: any };
export const useRemoveEmptyleg = (options?) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ aircraft_id, emptyleg: id }: DeleteEmptylegRequest) => {
      const { data } = await api.delete(
        `/operator/aircrafts/${aircraft_id}/emptylegs/${id}`,
      );
      return data;
    },
    {
      onMutate: req => {
        queryClient.setQueryData(
          ['ac_emptylegs', { ac_id: `${req.aircraft_id}` }],
          (previous?: Emptyleg[]) => {
            return previous?.filter(leg => leg.emptyleg_id != req.emptyleg);
          },
        );
      },
      ...options,
    },
  );
};

type HideEmptylegRequest = { emptyleg: Emptyleg; aircraft_id: any };
export const useHideEmptyleg = (options?) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ aircraft_id, emptyleg: emptyLeg }: HideEmptylegRequest) => {
      // unhide emptyleg
      if (emptyLeg.hidden) {
        const { data } = await api.post(
          `/operator/aircrafts/${aircraft_id}/emptylegs/unhide_emptyleg`,
          { emptyLeg },
        );
        return data;
      }

      // hide
      const { data } = await api.post(
        `/operator/aircrafts/${aircraft_id}/emptylegs/hide_emptyleg`,
        { emptyLeg },
      );
      return data;
    },
    {
      onMutate: req => {
        queryClient.setQueryData(
          ['ac_emptylegs', { ac_id: `${req.aircraft_id}` }],
          (previous?: Emptyleg[]) =>
            previous?.map(leg =>
              leg._id != (req.emptyleg as Emptyleg)._id
                ? leg
                : { ...leg, hidden: !leg.hidden },
            ),
        );
      },
      ...options,
    },
  );
};

type DuplicateEmptylegRequest = {
  emptyLeg: any;
  duplicateEmptyleg: any;
  aircraft_id: string;
};
export const useDuplicateEmptyLeg = (options?) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      emptyLeg,
      duplicateEmptyleg,
      aircraft_id,
    }: DuplicateEmptylegRequest) => {
      const { data } = await api.post<CreateEmptylegResponse>(
        `/operator/aircrafts/${aircraft_id}/emptylegs/duplicate`,
        { emptyLeg, duplicateEmptyleg },
      );
      return data;
    },
    {
      onMutate: req => {
        queryClient.invalidateQueries([
          'ac_emptylegs',
          { ac_id: `${req.aircraft_id}` },
        ]);
      },
      ...options,
    },
  );
};
