import { Aircraft } from '@/API/models';
import React from 'react';
import { FaMoneyBillAlt } from 'react-icons/fa';
import {
  FiAlertTriangle,
  FiArrowRight,
  FiEye,
  FiHome,
  FiMapPin,
  FiPlus,
  FiUser,
} from 'react-icons/fi';
import { ImAirplane } from 'react-icons/im';

const Property: React.FC<{ className?: string; onClick?: () => void }> = ({
  children,
  className,
  onClick,
}) => (
  <div
    className={`p-2 flex items-end gap-3 w-2/5 flex-auto ${className}`}
    onClick={onClick}
  >
    {children}
  </div>
);

const Btn: React.FC<{
  className?: string;
  onClick?: () => void;
  href?: string;
}> = ({ children, className, onClick, href }) => {
  return (
    <a
      href={href}
      className={`flex items-end gap-3 p-4 rounded-md cursor-pointer flex-1 min-w-max border border-solid btn btn-dimmed ${className}`}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

const NoImage: React.FC = () => (
  <div className='w-full h-72 bg-gray-200 text-gray-500 text-center flex justify-center items-center flex-col'>
    <ImAirplane size={40} />
    <p className='mt-10'>No Image</p>
  </div>
);
type Props = {
  aircraft: Aircraft;
  operatorId?: string;
  add: (ac: Aircraft) => void;
  view: (ac: Aircraft) => void;
};

const AircraftCard: React.FC<Props> = ({ aircraft, add, view, operatorId }) => {
  return (
    <div className='flex max-w-80-vw  flex-col transition-all shadow-md hover:shadow-lg text-gray-600'>
      <a href={`/operator/aircrafts/${aircraft.id}/edit`}>
        {aircraft.image ? (
          <div
            className='h-72 bg-gray-200 bg-center bg-cover'
            style={{ backgroundImage: `url(${aircraft.image})` }}
          />
        ) : (
          <NoImage />
        )}
        <Property className='m-4 text-xl w-max  max-w-full'>
          {/* <h3 className='px-8'>{aircraft.craft_type_model_name}</h3> */}
          <p>{`${aircraft.tail_number}, ${aircraft.price_config_name}, ${
            aircraft.aircraft_type ?? ''
          }`}</p>
        </Property>
      </a>
      <dl className='px-4 flex flex-wrap gap-2 mb-4 h-full'>
        <Property>
          <FiUser size={20} />
          <span>Max {aircraft.capacity}</span>
        </Property>
        <Property>
          <FaMoneyBillAlt size={20} />
          <span>${aircraft.wholesale_hourly_rate}/hr.</span>
        </Property>
        <Property>
          <FiHome size={20} />
          <span>{aircraft.home_vertiport_code ?? 'Home'}</span>
        </Property>
        <Property>
          <FiMapPin size={20} />
          <span>{aircraft.current_vertiport ?? 'Current'}</span>
        </Property>

        {/* {!aircraft.completed && ( */}
        {(!aircraft.completed || !aircraft.aircraft_type) && (
          <Property className='bg-yellow-100 w-full text-yellow-800 p-4 '>
            <FiAlertTriangle size={20} />
            <span>Aircraft Not Complete</span>
          </Property>
        )}
        <div className='flex flex-1 gap-3'>
          <Btn
            className='self-end justify-between'
            href={`/operator/aircrafts/${aircraft.id}/edit${
              operatorId ? `?operator_id=${operatorId}` : ''
            }`}
          >
            <span>Edit</span>
            <FiArrowRight size={20} />
          </Btn>
          <Btn
            className='self-end justify-between'
            href={`/operator/aircrafts/${aircraft.id}/aircraft_availabilities${
              operatorId ? `?operator_id=${operatorId}` : ''
            }`}
          >
            <span>Calendar</span>
            <FiArrowRight size={20} />
          </Btn>
        </div>
        <div className='flex flex-1 gap-3'>
          <Btn
            className='self-end justify-between'
            onClick={() => {
              view(aircraft);
            }}
          >
            <span>View Empty Legs</span>
            <FiEye size={20} />
          </Btn>
          <Btn
            className='self-end justify-between'
            onClick={() => {
              add(aircraft);
            }}
          >
            <span>Add Empty Leg</span>
            <FiPlus size={20} />
          </Btn>
        </div>
      </dl>
    </div>
  );
};
export default AircraftCard;
