import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import { FaWrench } from 'react-icons/fa';
import {
  FiCalendar,
  FiCheck,
  FiClock,
  FiDollarSign,
  FiHelpCircle,
  FiMapPin,
  FiSend,
  FiStar,
} from 'react-icons/fi';
import { EventForm } from '@/types/AddEventForms';
import { formatMoney } from '@/Utils/helpers';
import { EventApi } from '@fullcalendar/react';

type Props = {
  event?: EventApi;
  onClose: () => void;
};

const transientDetails = (event: EventApi) => {
  const {
    airport,
    unavailable_hours,
    unavailable_end,
    unavailable_start,
    date_range,
    event_title,
    event_mode,
  } = event.extendedProps as EventForm;
  return [
    {
      title: `${date_range.from?.toLocaleDateString()} ${
        date_range.to ? 'to ' + date_range.to?.toLocaleDateString() : ''
      }`,
      sub: 'date range',
      Icon: FiCalendar,
    },
    {
      title: `${airport.airport_code}`,
      sub: `at ${airport.location}`,
      Icon: FiMapPin,
    },
    unavailable_hours
      ? {
          title: `${unavailable_start} - ${unavailable_end}`,
          sub: `unavailable during these hours`,
          Icon: FiClock,
        }
      : {
          title: `Available for booking`,
          sub: `no blocked hours`,
          Icon: FiCheck,
        },
    event_mode
      ? {
          title: `${event_title}`,
          sub: `Event Title`,
          Icon: FiStar,
        }
      : {
          title: `N/A`,
          sub: `no event specified`,
          Icon: FiHelpCircle,
        },
  ];
};

const EmptylegDetails = (event: EventApi) => {
  const { departure_airport, arrival_airport, flight_time, price, date_range } =
    event.extendedProps as EventForm;
  return [
    {
      title: `${departure_airport.airport_code} > ${arrival_airport.airport_code}`,
      sub: `from ${departure_airport.location} > to ${arrival_airport.location}`,
      Icon: FiSend,
    },
    {
      title: `from ${date_range.from?.toLocaleDateString()} to ${date_range.to?.toLocaleDateString()}`,
      sub: 'date range',
      Icon: FiCalendar,
    },
    { title: `${flight_time} hours`, sub: 'Flight Time', Icon: FiClock },
    { title: formatMoney(price), sub: 'Price', Icon: FiDollarSign },
  ];
};

const MaintenanceDetails = (event: EventApi) => {
  const { date_range } = event.extendedProps as EventForm;
  return [
    {
      title: `Down For Maintenance`,
      sub: `the aircraft will be unavailable during this period`,
      Icon: FaWrench,
    },
    {
      title: `from ${date_range.from?.toLocaleDateString()} to ${date_range.to?.toLocaleDateString()}`,
      sub: 'date range',
      Icon: FiCalendar,
    },
  ];
};

const EventDetails: React.FC<Props> = ({ event, onClose }) => {
  if (!event) {
    return null;
  }
  const details = useMemo(() => {
    const { type } = event.extendedProps as EventForm;

    if (type === 'Transient') {
      return transientDetails(event);
    }

    if (type === 'Maintenance') {
      return MaintenanceDetails(event);
    }
    return EmptylegDetails(event);
  }, [event.extendedProps.type]);
  return <ShowEvent event={event} details={details} onClose={onClose} />;
};

type ShowProps = Props & {
  details: { title: string; sub: string; Icon: IconType }[];
};

const ShowEvent: React.FC<ShowProps> = ({ event, details, onClose }) => {
  return (
    <div className='flex flex-wrap'>
      <section className='text-gray-600 body-font lg:w-2/3 w-full'>
        <h1 className='text-3xl'>{event!.title}</h1>
        <div className='container px-5 py-24 mx-auto flex flex-wrap'>
          {details.map(({ title, sub, Icon }) => (
            <div
              key={title}
              className='flex relative pb-20 items-center w-2/3 mx-auto'
            >
              <div className='h-full w-6 absolute inset-0 flex items-center justify-center'>
                <div className='h-full w-1 bg-gray-200 pointer-events-none'></div>
              </div>
              <div className='flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm'></div>
              <div className='flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row'>
                <div className='flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center'>
                  <Icon className='w-12 h-12' size={20} />
                </div>
                <div className='flex-grow sm:pl-6 mt-6 sm:mt-0'>
                  <h2 className='font-medium title-font text-gray-900 mb-1 text-3xl'>
                    {title}
                  </h2>
                  <p className='text-xl leading-relaxed'>{sub}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className='lg:w-1/3 w-full flex flex-col justify-center items-center gap-5'>
        <h1 className='text-3xl'>Actions</h1>
        <button
          className='btn btn-dimmed w-full'
          onClick={() => {
            event?.remove();
            onClose();
          }}
        >
          Delete
        </button>
        {/* <button
          className='btn btn-dimmed w-full'
          onClick={() => {
            // event?.mod();
            onClose();
          }}
        >
          Edit
        </button> */}
      </section>
    </div>
  );
};
export default EventDetails;
