/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
// ${path}
export const yupCustom = {
  mixed: {
    required: 'required field',
    notType: 'not a valid value',
  },
  number: {
    min: 'value must be more than ${min}',
    max: 'value must be less than ${max}',
    positive: 'value must be positive',
  },
};
yup.setLocale(yupCustom);
export { yup };
