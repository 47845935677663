import { ErrorMessage, FormElement, InputProps } from '@/components/Forms';
import useDebounce from '@/Utils/useDebounce';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';

type Props = InputProps;

const DebouncedInput: React.FC<Props> = ({ preText, ...props }) => {
  const [{ value }, {}, { setValue }] = useField(props);
  const [temp, setTemp] = useState(value);
  const debounced = useDebounce(temp);

  useEffect(() => {
    setValue(debounced);
  }, [debounced]);

  useEffect(() => {
    if (temp !== value) {
      setTemp(value);
    }
  }, [value]);

  return (
    <FormElement {...props}>
      <div className='flex items-baseline'>
        {preText && (
          <p className='pr-1 inline-block text-gray-700 font-bold'>{preText}</p>
        )}
        <input
          {...props}
          className='placeholder-gray-500'
          placeholder={props.placeholder || '....'}
          type={props.type || 'text'}
          id={props.name}
          onChange={e => {
            const currentValue = e.currentTarget.value;
            if (props.type !== 'number') {
              return setTemp(currentValue);
            }
            if (currentValue.length === 0) {
              return setTemp('');
            }

            let newVal = parseInt(currentValue);

            const max = parseInt(props.max as string);
            const min = parseInt(props.min as string);
            if ((max && newVal > max) || isNaN(newVal)) {
              newVal = max;
            }
            if (min !== undefined && newVal < min) {
              newVal = min;
            }

            setTemp(`${newVal}`);
          }}
          onBlur={() => {
            if (isNaN(parseInt(value))) {
              setTemp(props.max);
            }
          }}
          value={temp ?? props.value ?? ''}
        />
        {props.text && (
          <p className='pl-2 inline-block text-gray-500 font-bold'>
            {props.text}
          </p>
        )}
        {props.children}
      </div>
      <ErrorMessage name={props.name} />
    </FormElement>
  );
};
export default DebouncedInput;
